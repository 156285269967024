<!-- FOOTER (sticky) -->
<footer id="footer">
  <!-- content -->
  <div class="container">
    <div class="row">
      <div class="col-12 text-center pt-5 pb-3">
        <a [routerLink]="['/']" class="logo-container">
          <img
            src="../../../assets/images/logos/bookyourtrail_grau.svg"
            width="220px"
            height="44px"
            alt="BookYourTrail® Logo"
            class="logo-footer" />
        </a>
      </div>
    </div>

    <div class="row pt-5 mb-0 mb-lg-4">
      <div
        class="col-sm-12 col-lg-2 text-center text-lg-start mb-5 mb-lg-0 px-5 border-right">
        <h5>{{ 'byt_footer_informations' | translate }}</h5>
        <ul class="list-unstyled list-orange-icon">
          <li>
            <a
              appInquiryModal
              [attr.aria-label]="'byt_header_contact' | translate"
              [routerLink]="['./']"
              >{{ 'byt_contact' | translate }}</a
            >
          </li>
          <li>
            <a [routerLink]="['/contents/', 'slug_imprint' | translate]">{{
              'byt_imprint' | translate
            }}</a>
          </li>
          <li>
            <a [routerLink]="['/contents/', 'slug_privacy' | translate]">{{
              'byt_privacy' | translate
            }}</a>
          </li>
          <li (click)="onOpenCookieSettings($event)">
            {{ 'byt_footer_cookies' | translate }}
          </li>
        </ul>
      </div>

      <div
        class="col-sm-12 col-lg-4 text-center text-lg-start mb-5 mb-lg-0 px-5 border-right">
        <h5>{{ 'byt_footer_aboutUs' | translate }}</h5>
        <ul class="list-unstyled list-orange-icon">
          <li>
            <a
              [href]="siteSettings.links[currLang]?.website"
              target="_blank"
              [innerHtml]="'byt_footer_info_1' | translate"></a>
          </li>
          <li>
            <a
              [href]="siteSettings.links[currLang]?.consulting"
              target="_blank">
              {{ 'byt_footer_info_2' | translate }}</a
            >
          </li>
          <li>
            <a
              [href]="siteSettings.links[currLang]?.trekking"
              target="_blank"
              >{{ 'byt_footer_info_3' | translate }}</a
            >
          </li>
        </ul>
      </div>
      <div
        class="col-sm-12 col-lg-4 text-center text-lg-start mb-5 mb-lg-0 px-5 border-right">
        <h5>{{ 'byt_footer_forTravelers' | translate }}</h5>
        <ul class="list-unstyled list-orange-icon">
          <li>
            <a [routerLink]="['/contents/', 'slug_destinations' | translate]">{{
              'byt_travelInfo' | translate
            }}</a>
          </li>
          <li>
            <a
              [routerLink]="['/contents/', 'slug_travel-insurance' | translate]"
              >{{ 'byt_insurance' | translate }}</a
            >
          </li>
          <li>
            <a
              [routerLink]="['/contents/', 'slug_payment-policy' | translate]"
              >{{ 'byt_cancellation' | translate }}</a
            >
          </li>
          <li>
            <a [routerLink]="['/contents/', 'slug_tos' | translate]">{{
              'byt_tos' | translate
            }}</a>
          </li>
        </ul>
      </div>

      <div class="col-12 col-lg-2 text-center text-lg-start px-5">
        <!-- Footer Nav -->
        <app-trustbox-footer></app-trustbox-footer>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-0 mt-lg-5 mb-4 text-center">
        <div class="align-top">
          {{ 'byt_copyright' | translate }}
          <div class="version-next-line"><br /></div>
          <span class="small"> Platform (V. {{ version }}) </span>
        </div>
      </div>
    </div>
  </div>
</footer>
