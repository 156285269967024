<ng-container *ngIf="offer">
    <div class="d-none d-print-block">
        <div class="row">
            <div class="col-4">
                {{ 'byt_trail' | translate }}
            </div>
            <div class="col-8">
                <b>{{ offer.trail?.name }}</b>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                {{ 'byt_offer_code' | translate }}
            </div>
            <div class="col-8">
                <b>{{ offer.code }}</b>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                {{'byt_bookingDate' | translate}}
            </div>
            <div class="col-8">
                {{ created | date:'dd.MM.yyyy' }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                {{'byt_client' | translate}}
            </div>
            <div class="col-8">
                {{ offer.client?.firstname }} {{ offer.client?.lastname }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                {{'byt_travelers' | translate}}
            </div>
            <div class="col-8">
                {{ offer.travelers.length }}
            </div>
        </div>
    </div>
</ng-container>