<div class="row page-break-after">
    <div class="mt-4 pt-3 mb-3 page-break-after">
        <h3 class="mb-4">{{'byt_yourTour' | translate}}</h3>
        <!-- day component -->
        <app-tour-days *ngIf="offer" [offer]="offer"></app-tour-days>

        <!-- room type info -->
        <div class="mt-3">
            <small>
                <strong>{{'room_types' | translate}}:</strong>
            </small>
        </div>
        <p class="small p-0 room-description">
            <span class="badge single">{{'Single' | translate}}</span>
            {{'singleroom_desc' | translate}} |
            <span class="badge double">{{'Double' | translate}}</span>
            {{'doubleroom_desc' | translate}} |
            <span class="badge multi">{{'Multi' | translate}}</span>
            {{'multiroom_desc' | translate}} |
            <span class="badge mattresses">{{'Mattresses' | translate}}</span>
            {{'mattresses_desc' | translate}}
        </p>
    </div>
</div>