<!-- sidebar tour info -->
<div class="mb-3 bg-grey p-4">

  <!-- Quick Info -->
  <div class="text-center">
    <h3 class="text-nightgreen mb-4">{{'byt_quick_info' | translate}}</h3>

    {{ trail?.name }}<br>
    {{ startStage?.code }} - {{ endStage?.code }}

    <div class="text-muted">{{ request?.date | date:'dd.MM.yyyy' }} - {{ endDate | date:'dd.MM.yyyy' }} ({{ days + 1 }}
      Tage)</div>
  </div>

  <hr class="my-4" *ngIf="!isLoading && !showRequestForm">

  <div class="row text-orange" *ngIf="!isLoading && !showRequestForm">
    <div class="col-8">
      {{ 'tour_total' | translate}}
    </div>
    <div class="col-4 text-end">
      {{ price?.total | currencyFormat }}
    </div>
  </div>
  <!-- Price per traveler -->
  <div class="row text-muted" *ngIf="!isLoading && !showRequestForm">
    <div class="col-8 text-small">{{'per_person' | translate }}</div>
    <div class="col-4 text-end">{{ price.total_per_person | currencyFormat }}</div>
  </div>

  <hr class="my-4" *ngIf="!isLoading">

  <!-- TIMER -->
  <div class="timer-bg" *ngIf="showTimer">
    <div *ngIf="timer">
      <p class="text-center mb-1">{{ 'timer_title' | translate }}</p>
    </div>
    <div>
      <p class="timer-time text-center mb-0">{{ timer }}</p>
    </div>
  </div>
</div>
