import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { ConfirmModalComponent } from '@components/confirm-modal/confirm-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { filter, map } from 'rxjs/operators';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerService {
  constructor(
    private baseService: BaseService,
    private swUpdate: SwUpdate,
    private modalService: BsModalService
  ) {}

  public initListener() {
    if (
      this.baseService.getEnv().production &&
      this.baseService.isBrowser() &&
      navigator.serviceWorker
    ) {
      this.swUpdate.versionUpdates
        .pipe(
          filter(
            (evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'
          ),
          map((evt: any) => {
            return {
              type: 'UPDATE_AVAILABLE',
              current: evt.currentVersion,
              available: evt.latestVersion
            };
          })
        )
        .subscribe(() => {
          this.showModal();
        });
    }
  }

  private showModal(): void {
    const initialState: any = {
      title: 'toast_sw_update_title',
      message: 'toast_sw_update_message',
      confirmText: 'toast_sw_update_button',
      confirmCallback: () => {
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    };
    this.modalService.show(ConfirmModalComponent, {
      keyboard: false,
      backdrop: 'static',
      ignoreBackdropClick: true,
      initialState
    });
  }
}
