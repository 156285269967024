/*
* This component is used to render a fullscreen loading spinner.
*/

import { Component } from '@angular/core';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.less']
})

export class SpinnerComponent {}