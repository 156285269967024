import { Injectable } from '@angular/core';
import { DirectusCollections } from '@enums/directus-collections.enum';
import { Activity } from '@models/activity';
import { DirectusService } from '@services/directus/directus.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {
  private activities: Activity[];

  constructor(private directusService: DirectusService) {}

  /*
   * Fetch activities from the API and parse data or retrieve cached ones
   */
  public get(fields?: any, force = false): Observable<Activity[]> {
    if (!this.activities || force) {
      return this.directusService
        .getItems(DirectusCollections.ACTIVITIES, fields, 'order')
        .pipe(
          tap(activities => {
            this.activities = activities;
          })
        ) as Observable<Activity[]>;
    } else {
      return of(this.activities);
    }
  }
}
