import { Injectable } from '@angular/core';
import { AppConstants } from '@enums/app-constants.enum';
import { Auth } from '@interfaces/auth.interface';
import { Credentials } from '@interfaces/credentials.interface';
import { BaseService } from '@services/base/base.service';
import { HttpService } from '@services/http/http.service';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
/*
 * AuthService is responsible for managing user actions (login, refresh session, get user data).
 */
export class AuthService {
  constructor(
    private baseService: BaseService,
    private httpService: HttpService
  ) {}

  /*
   * Getter method to retrieve token
   */
  get Token(): string | null {
    return this.baseService.getCookie(AppConstants.TOKEN) || null;
  }

  /*
   * Setter method to set token
   */
  set Token(value: any) {
    this.baseService.setCookie(AppConstants.TOKEN, value);
  }

  /*
   * Used to get token and user data from api
   */
  public async login(credentials: Credentials): Promise<any> {
    return await this.httpService
      .post('/login', credentials)
      .pipe(
        map((response: any) => response.data),
        tap((data: Auth) => {
          this.Token = data.token;
          this.baseService.setCookie(AppConstants.TOKEN, data.token);
          this.baseService.setIsLoggedIn(true);
        })
      )
      .toPromise();
  }

  /*
   * Inform application that token has been destroyed
   */
  public logout(): void {
    this.Token = null;
    this.baseService.cookieService.deleteAll('/ ');
    this.baseService.cookieService.delete(AppConstants.TOKEN, '/ ');
    this.baseService.setIsLoggedIn(false);
    this.baseService.router.navigate(['/'], { replaceUrl: true });
  }
}
