import { SiteSettings } from '@interfaces/site-settings';

export const siteSettings: SiteSettings = {
  company: {
    address: '9821 Obervellach 15',
    country: 'Austria',
    name: 'Trail Angels GmbH'
  },
  socialMedia: {
    facebook: 'https://www.facebook.com/trailangelsofficial',
    instagram: 'https://www.instagram.com/trailangels.official'
  },
  links: {
    de: {
      blog: 'https://www.trail-angels.com/einblicke/',
      byt: 'https://www.trail-angels.com/nachhaltige-reisen/',
      consulting: 'https://www.trail-angels.com/consulting/',
      support: 'https://www.trail-angels.com/trail-angels-info-buchungscenter/',
      trekking: 'https://www.trail-angels.com/trekking/',
      website: 'https://www.trail-angels.com',
      hstroute:
        'https://www.high-scardus-trail.com/de/tour/fernwanderweg/high-scardus-trail-gesamtroute/66235694/',
      hstaccommodations:
        'https://www.high-scardus-trail.com/de/p/uebersicht-der-unterkuenfte/66060581/'
    },
    en: {
      blog: 'https://www.trail-angels.com/en/insights/',
      byt: 'https://www.trail-angels.com/en/responsible-travel/',
      consulting: 'https://www.trail-angels.com/en/consulting/',
      support:
        'https://www.trail-angels.com/en/trail-angels-info-booking-center/',
      trekking: 'https://www.trail-angels.com/en/trekking/',
      website: 'https://www.trail-angels.com/en',
      hstroute:
        'https://www.high-scardus-trail.com/en/tour/long-distance-hiking-trail/high-scardus-trail-overview/66235694/',
      hstaccommodations:
        'https://www.high-scardus-trail.com/en/p/overview-accommodations/66060581/'
    }
  },
  phone: '+43 4782 93093',
  mail: 'info@trail-angels.com'
};
