import { Component, Input } from '@angular/core';
import { BaseComponent } from '@components/base/base.component';
import { BaseService } from '@services/base/base.service';
import { SocketService } from '@services/socket/socket.service';
import { PlannerEvents } from '@enums/planner-events.enum';
import { TrailsService } from '@services/trails/trails.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-checkout-sidebar',
  templateUrl: 'checkout-sidebar.component.html',
  styleUrls: ['checkout-sidebar.component.less']
})

/*
 * Displays time and summary of tour.
 */
export class CheckoutSidebarComponent extends BaseComponent {
  // declaration of fields
  @Input() days: number;
  @Input() request: any;
  @Input() trail: any;
  @Input() isLoading: boolean;
  @Input() showRequestForm: boolean;
  @Input() showTOS: boolean;
  @Input() showTimer: boolean;

  endDate: any;
  endStage: any;
  price: any;
  startStage: any;
  timer: string | null;

  constructor(
    public baseService: BaseService,
    public socketService: SocketService,
    public trailService: TrailsService
  ) {
    super(baseService);
    this.days = 0;
    this.endDate = null;
    this.endStage = {};
    this.price = this.socketService.getPriceObject();
    this.request = {};
    this.startStage = {};
    this.timer = null;
    this.trail = {};
  }

  ngOnInit() {
    this.socketService.Event.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (event: any) => {
        switch (event.name) {
          case PlannerEvents.get_prices:
          case PlannerEvents.reset_prices:
            this.price = this.socketService.getPriceObject();
            break;
          case PlannerEvents.show_timeout:
            this.timer = event.data.timeout;
            break;
          case PlannerEvents.prices:
            this.price = event.data;
            break;
          default:
        }
      }
    );
  }

  ngOnChanges() {
    // filter out stages from trail object
    if (
      this.request &&
      Object.keys(this.request).length > 0 &&
      this.trail &&
      Object.keys(this.trail).length > 0
    ) {
      const tempDate = new Date(this.request.date);
      tempDate.setDate(tempDate.getDate() + this.days);
      this.endDate = tempDate.toISOString().substr(0, 10);
      this.endStage = this.trailService.filterStages(
        this.trail.stages,
        this.request.end_stage
      );
      this.startStage = this.trailService.filterStages(
        this.trail.stages,
        this.request.start_stage
      );
    }
  }
}
