/* eslint-disable no-underscore-dangle */
/*
 * Base class for all other services. Sets some default values for all child services.
 * Also holds base configuration, depending on environment.
 */

import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import { ToastService } from '../toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { AppEnvService } from '@services/app-env/app-env.service';
import { Enviroment } from '@interfaces/environment.interface';
import { TransferState } from '@angular/platform-browser';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { AppConstants } from '@enums/app-constants.enum';

@Injectable()
export class BaseService {
  // declarations of subscribers
  private _currentLang: BehaviorSubject<string>;
  private _isLoggedIn: BehaviorSubject<boolean>;

  constructor(
    public appEnvService: AppEnvService,
    public cookieService: CookieService,
    public injector: Injector,
    public route: ActivatedRoute,
    public router: Router,
    public state: TransferState,
    public toastService: ToastService,
    public translate: TranslateService
  ) {
    // set defaults
    const isLoggedIn = this.getCookie(AppConstants.TOKEN) ? true : false;
    this._isLoggedIn = new BehaviorSubject(isLoggedIn);

    // subscribe to lang service in case language is changed
    this._currentLang = new BehaviorSubject(this.translate.getDefaultLang());

    this.translate.onLangChange.subscribe(translateObj => {
      // set currentLang
      this.setCookie(AppConstants.LANGUAGE, translateObj.lang);
      this._currentLang.next(translateObj.lang);
    });
  }

  /*
   * Getter method to return language as observable
   */
  get currentLang(): Observable<string> {
    return this._currentLang.asObservable();
  }

  /*
   * Getter method to return isLoggedIn state as observable
   */
  get isLoggedIn() {
    return this._isLoggedIn.asObservable();
  }
  /**
   * Method to retrieve info if platform is browser or server
   */
  public isBrowser(): boolean {
    return this.appEnvService.isBrowser();
  }
  /**
   * Method to retrieve info if platform is browser or server
   */
  public isServer(): boolean {
    return this.appEnvService.isServer();
  }

  /*
   * Setter method to update state of observable
   */
  setIsLoggedIn(state: boolean) {
    this._isLoggedIn.next(state);
  }

  /*
   * Retrieve api url.
   */
  public getApiUrl(path: string): string {
    return this.appEnvService.getEnv().apiUrl + path;
  }

  /**
   * Retrieve whole environment configuration.
   */
  public getEnv(): Enviroment {
    return this.appEnvService.getEnv();
  }

  /*
   * Pass key and retrieve specfic cookie.
   */
  public getCookie(key: string): string | null {
    if (this.isServer()) {
      const req = this.injector.get(REQUEST);
      const name = encodeURIComponent(key);
      const regExp = new RegExp(
        '(?:^' + name + '|;\\s*' + name + ')=(.*?)(?:;|$)',
        'g'
      );
      const result = regExp.exec(req.headers.cookie as any);
      return result ? decodeURIComponent(result[1]) : null;
    } else {
      return this.cookieService.get(key) ? this.cookieService.get(key) : null;
    }
  }

  public setCookie(key: string, value: any): void {
    this.cookieService.set(key, value, 30, '/');
  }

  /*
   * Pass key and retrieve stored localstorage.
   */
  public getData(key: any): any {
    if (this.isBrowser()) {
      const data = localStorage.getItem(key);
      try {
        return JSON.parse(data as any);
      } catch (err) {
        return null;
      }
    } else {
      return null;
    }
  }

  /*
   * Pass key and data and store it in localstorage.
   */
  public setData(key: any, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  /*
   * Pass key and data and store it in localstorage.
   */
  public removeData(key: any): void {
    localStorage.removeItem(key);
  }

  /*
   * Pass key and retrieve stored from server state transfer.
   */
  public getState(key: any): any {
    return this.state.get(key, null);
  }

  /*
   * Pass key and data and store it for server state transfer.
   */
  public setState(key: any, data: any): void {
    this.state.set(key, data);
  }

  /*
   * Method to extract texts out of text object.
   */
  public getText(texts: Array<object>, prop?: string): string {
    let data = '';

    if (
      texts !== undefined &&
      texts !== null &&
      this._currentLang.getValue() != null
    ) {
      texts.forEach((text: any) => {
        if (text.language === this._currentLang.getValue()) {
          data = prop ? text[prop] : text.description;
        }
      });
    }

    return data.replace(/\n/g, '<br>');
  }

  /*
   * Method to extract texts out of text directus translations object.
   */
  public getDirectusText(item: any, prop?: string) {
    prop = prop ? prop : 'description';
    if (this._currentLang.getValue() !== 'de' && item.translations.length) {
      const translation = item.translations.filter((translation: any) => {
        if (translation.languages_code) {
          const languageCode = translation.languages_code.split('-')[0];
          return languageCode === this._currentLang.getValue();
        } else {
          return false;
        }
      });
      return translation.length && translation[0][prop]
        ? String(translation[0][prop])
        : String(item[prop]);
    } else {
      return String(item[prop]);
    }
  }
}
