import { Component, Input } from '@angular/core';
import { BaseComponent } from '@components/base/base.component';
import { Offer } from '@models/offer';

@Component({
    selector: 'app-booking-payment-sidebar',
    templateUrl: 'payment-sidebar.component.html',
    styleUrls: ['payment-sidebar.component.less']
})

/*
* Displays time and summary of booked tour.
*/

export class BookingPaymentSidebarComponent extends BaseComponent {

    // declaration of fields
    @Input() offer: Offer;
    @Input() isLoading: boolean;

    public endDate: string;
    public endStage: string;
    public startDate: string;
    public startStage: string;
    public tourDays: number;

    ngOnChanges() {
        // filter out stages from trail object
        if (this.offer) {
            this.tourDays = this.offer.accommodation.details.length;
            this.endDate = this.offer.accommodation.details[this.tourDays - 1].date;
            this.endStage = this.offer.accommodation.details[this.tourDays - 1].stage.code;
            this.startDate = this.offer.accommodation.details[0].date;
            this.startStage = this.offer.accommodation.details[0].stage.code
        }
    }
}