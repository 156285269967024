<ng-container [formGroup]="formGroup">
  <div class="row mt-0">
    <div class="col-12">
      <div class="form-check">
        <label class="form-check-label">
          <input
            class="form-check-input d-flex"
            formControlName="tos"
            type="checkbox"
            required />
          <p class="small">
            {{ 'byt_accept_terms' | translate }}<br />
            <a
              [routerLink]="['/contents/', 'slug_tos' | translate]"
              target="_blank"
              >{{ 'byt_tos' | translate }}</a
            >
            |
            <a
              [routerLink]="['/contents/', 'slug_privacy' | translate]"
              target="_blank"
              >{{ 'byt_privacy' | translate }}</a
            >
            |
            <a
              href="{{ trail.information.terms | getText: currLang : 'url' }}"
              target="_blank"
              >{{ 'byt_tos_touroperator' | translate }}</a
            >
          </p>
        </label>
      </div>
    </div>

    <!-- accept marketing -->
    <div class="col-12" *ngIf="!isLoggedIn">
      <div class="form-check">
        <label class="form-check-label">
          <input
            class="form-check-input d-flex"
            formControlName="marketing_agreed"
            type="checkbox" />
          <p class="small" [innerHTML]="'byt_accept_marketing' | translate"></p>
        </label>
      </div>
    </div>
  </div>
</ng-container>
