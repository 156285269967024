import { Component } from '@angular/core';
import { BaseComponent } from '@components/base/base.component';
import { BaseService } from '@services/base/base.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: 'confirm-modal.component.html',
  styleUrls: ['confirm-modal.component.less']
})
export class ConfirmModalComponent extends BaseComponent {
  public confirmText: string;
  public confirmCallback: () => {};
  public declineCallback: () => {};
  public declineText: string;
  public message: string;
  public title: string;

  constructor(
    public baseService: BaseService,
    public modalRef: BsModalRef
  ) {
    super(baseService);
  }
  confirm(): void {
    this.modalRef?.hide();
    this.confirmCallback();
  }

  decline(): void {
    this.modalRef?.hide();
    this.declineCallback();
  }
}
