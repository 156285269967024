<ng-container *ngIf="offer?.client?.type === 'B2C'">
    <hr>
    <!-- B2C Information -->
    <div class="row">
        <!-- infos and links to trail detail infos -->
        <div class="mb-4 page-break-after">
            <!-- tour operator -->
            <div class="mb-2">
                <span class="text-beige">{{'byt_operator' | translate}}</span><br>
                {{ offer.trail?.owner?.name }}<br>
                {{ offer.trail?.owner?.address?.formatted_address }}<br>
                {{ offer.trail?.owner?.phone }}<br>
                <a href="mailto: {{ offer.trail?.owner?.email }}" target="_blank">{{ offer.trail?.owner?.email }}</a>
                <br><br>

                <span class="text-beige">{{'byt_prg_title' | translate}}</span>
                <br>
                <!-- Legal info offer/booking -->
                <ng-container *ngIf="!isBooking">
                    {{'byt_offer_legal_info1' | translate}}
                    <br><br>
                    <span [innerHTML]="'byt_offer_legal_info2' | translate "></span>
                    <a href="https://www.bookyourtrail.com/contents/stdinfo" target="_blank">https://www.bookyourtrail.com/contents/stdinfo</a>
                </ng-container>

                <!-- Legal info booking -->
                <ng-container *ngIf="isBooking">
                    {{'byt_booking_text_prg14' | translate}}
                    <br><br>
                    {{'byt_booking_text_prg11' | translate}}
                </ng-container>
                <br><br>

                <!-- TOS -->
                <span class="text-beige">{{'byt_TOS' | translate}}</span>
                <br>
                {{'byt_booking_text_tos' | translate}}
                <a *ngIf="offer.trail" href="{{ offer.trail.information.terms | getText: currLang:'url' }}"
                    target="_blank">
                    {{ offer.trail.information.terms | getText:currLang:'url' }}
                </a>
            </div>
        </div>
    </div>
</ng-container>