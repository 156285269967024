import { Component, Input, OnChanges } from '@angular/core';
import { BaseComponent } from '@components/base/base.component';
import { Offer } from '@models/offer';

@Component({
  selector: 'app-offer-trail-info',
  templateUrl: './trail-info.component.html',
  styleUrls: ['./trail-info.component.less']
})
export class OfferTrailInfoComponent extends BaseComponent implements OnChanges {

  @Input() offer: Offer;
  @Input() showContact: boolean = false;

  public mailTo: string = '';

  ngOnChanges() {
    if (this.offer) {
      const mail = this.offer.trail?.owner.email;
      const subject = this.baseService.translate.instant('byt_offer_mailto1');
      const body = this.baseService.translate.instant('byt_offer_mailto2');
      const text = this.baseService.translate.instant('byt_offer_mailto3');
      this.mailTo = `mailto:${mail}?subject=${subject}${this.offer.trail?.name}&body=${body}${this.offer.code}, ${text}`;
    }
  }

}
