/*
* CurrencyFormat is pipe used to format numbers for specific currencies.
*/
import { Pipe, PipeTransform } from '@angular/core';
import { getYear } from 'date-fns';
import { getDaysInMonth } from 'date-fns';

@Pipe({
    name: 'daterangeCalendar'
})
export class DaterangeCalendar implements PipeTransform {

    transform(months: any): any {
        const enabledDaysArray = months.filter((month: any, index: number) => {
            month.index = index;
            return month.value === true;
        }).map((month: any) => { return month.index });

        const year = getYear(new Date());
        for (let i = year; i <= year + 2; i++) {
            enabledDaysArray.forEach((value: number) => {
                const days = getDaysInMonth(new Date(i, value));
                if (days !== NaN) {
                    for (let j = 1; j <= days; j++) {
                        enabledDaysArray.push(new Date(i, value, j));
                    }
                }
            });
        }
        return enabledDaysArray;
    }
}
