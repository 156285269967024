import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate } from '@angular/router';
import { ConfirmModalComponent } from '@components/confirm-modal/confirm-modal.component';
import { BasePage } from '@pages/base/base.page';
import { BaseService } from '@services/base/base.service';
import { SocketService } from '@services/socket/socket.service';
import { BsModalService } from 'ngx-bootstrap/modal';

/*
 * PlannerGuard is to used to control exist intents oon planner pages
 */

@Injectable()

export class PlannerGuard implements CanActivate, CanDeactivate<BasePage> {

    constructor(
        private baseService: BaseService,
        private modalService: BsModalService,
        private socketService: SocketService
    ) { }

    async canActivate(): Promise<boolean> {
        return true;
    }

    async canDeactivate(page: BasePage, currentRoute: ActivatedRouteSnapshot): Promise<boolean> {
        if (this.socketService.isActive && !page.canBeDeactivated) {
            let resolveFunction: (confirm: boolean) => void;
            const initialState: any = {
                title: 'byt_unsaved_changes_title',
                message: 'byt_unsaved_changes_message',
                confirmText: 'byt_unsaved_changes_button',
                confirmCallback: () => {
                    if (this.socketService.isConnected) {
                        this.socketService.disconnect(false);
                        // check if user is on checkout page if so redirect back to trail detail page
                        this.baseService.router.navigate(['/trail', currentRoute.params.trailId], { replaceUrl: true });
                        resolveFunction(true);
                    } else {
                        resolveFunction(false);
                    }
                },
                declineText: 'byt_button_no',
                declineCallback: () => {
                    resolveFunction(false);
                }
            };
            this.modalService.show(ConfirmModalComponent, {
                keyboard: false,
                backdrop: 'static',
                ignoreBackdropClick: true,
                initialState
            });
            return new Promise<boolean>(resolve => {
                resolveFunction = resolve;
            });
        } else {
            return true;
        }
    }

}
