<div id="mainImage" class="container-fluid px-0 mb-3">
  <!-- images -->
  <img
    class="img-header d-none d-sm-block"
    width="2000"
    height="949"
    [lazyLoad]="imageLandscape"
    [alt]="title"
    [defaultImage]="defaultImage" />
  <img
    class="img-header d-block d-sm-none"
    width="500"
    height="800"
    [lazyLoad]="imagePortrait"
    [alt]="title"
    [defaultImage]="defaultImage" />

  <div class="img-overlay"></div>
  <!-- title and claim -->
  <div class="claim-header">
    <h1 class="mt-4" [innerHtml]="title"></h1>
    <h2 class="mt-1 text-white" [innerHtml]="claim"></h2>
    <button
      *ngIf="ctaText"
      (click)="onClickCta()"
      type="button"
      class="btn btn-small btn-orange mt-1">
      {{ ctaText | translate }}
    </button>
  </div>
</div>
