import { Injectable } from '@angular/core';
import { DirectusCollections } from '@enums/directus-collections.enum';
import { Destination } from '@models/destinations';
import { DirectusService } from '@services/directus/directus.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DestinationsService {

    private destinations: Destination[];

    constructor(
        private directusService: DirectusService
    ) {}

    /*
    * Fetch destinations from the API and parse data or retrieve cached ones
    */
    public get(fields?: any, force = false): Observable<Destination[]> {
        if (!this.destinations || force) {
            return this.directusService.getItems(DirectusCollections.DESTINATIONS, fields, 'order').pipe(tap(destinations => { this.destinations = destinations; })) as Observable<Destination[]>;
        } else {
            return of(this.destinations);
        }
    }

}