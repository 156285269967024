import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Directives
import { BackgroundImageDirective } from './background-image/background-image.directive';
import { InquiryModalDirective } from './inquiry-modal/inquiry-modal.directive';
import { PrinterDirective } from './print/print.directive';
import { ProductTypesDirective } from './product-types/product-types.directive';
import { ShellNoRendererDirective } from './shell-no-renderer/shell-no-renderer.directive';
import { ShellRendererDirective } from './shell-renderer/shell-renderer.directive';
import { ShowIfTrueDirective } from './show-if-true/show-if-true.directive';
import { SubmitFormDirective } from './submit-form/submit-form.directive';

const DIRECTIVES = [
  BackgroundImageDirective,
  InquiryModalDirective,
  PrinterDirective,
  ProductTypesDirective,
  ShellNoRendererDirective,
  ShellRendererDirective,
  ShowIfTrueDirective,
  SubmitFormDirective
];

@NgModule({
  imports: [CommonModule],
  declarations: [...DIRECTIVES],
  exports: [...DIRECTIVES]
})
export class DirectivesModule {}
