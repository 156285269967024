/* eslint-disable @typescript-eslint/naming-convention */
/*
 * Compared to splitted component for planner, this component is justed used to display prices for bookings/offers after they have been created.
 */

import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@components/base/base.component';

@Component({
  selector: 'app-tour-prices',
  templateUrl: 'tour-prices.component.html',
  styleUrls: ['tour-prices.component.less']
})
export class TourPricesComponent extends BaseComponent implements OnInit {
  @Input() offer: any;

  // declaration of fields and form
  public price_per_person = 0;

  ngOnInit() {
    // if input changes (after async call), set calculated values
    if (this.offer && Object.keys(this.offer).length > 0) {
      this.price_per_person =
        this.offer.total_price / this.offer.travelers.length;
    }
  }
}
