/*
* This component is used to render a corner icon.
*/

import { Component } from '@angular/core';

@Component({
    selector: 'app-corner-icon',
    templateUrl: './corner-icon.component.html',
    styleUrls: ['./corner-icon.component.less']
})

export class CornerIconComponent {}