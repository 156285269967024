export enum ProductTypes {
    Single = 'Single',
    Double = 'Double',
    Multi = 'Multi',
    Mattresses = 'Mattresses',
    PassengerTransport = 'PassengerTransport',
    LuggageTransport = 'LuggageTransport',
    BackTransport = 'BackTransport',
    MobilityService = 'MobilityService',
    AngelService = 'AngelService',
    Guide = 'Guide',
    Flight = 'Flight',
    Shuttle = 'Shuttle',
    Ticket = 'Ticket',
    Package = 'Package'
}