<ng-container *ngIf="offer">
    <!-- Tour Summary-->
    <div class="mb-3 bg-grey p-4">

        <!-- Quick Info -->
        <div class="text-center">
            <h3 class="text-nightgreen mb-4">{{'byt_quick_info' | translate}}</h3>
            {{ offer.client?.firstname }} {{ offer.client?.lastname }}
            <br>
            {{ offer.trail?.name }}
            <br>
            {{ startStage }} - {{ endStage }}
            <div class="text-muted">{{ startDate | date:'dd.MM.yyyy' }} - {{ endDate | date:'dd.MM.yyyy' }} ({{ tourDays
                }}
                Tage)</div>
        </div>

        <hr class="my-4">

        <div class="row text-orange">
            <div class="col-8">
                {{ 'tour_total' | translate}}
            </div>
            <div class="col-4 text-end">
                {{ offer.total_price | currencyFormat }}
            </div>
        </div>
        <!-- Price per traveler -->
        <div class="row text-muted">
            <div class="col-8 text-small">{{'per_person' | translate }}</div>
            <div class="col-4 text-end">{{ (offer.total_price / offer.travelers.length) | currencyFormat }}</div>
        </div>
    </div>
</ng-container>