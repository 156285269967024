import { Component, Input } from '@angular/core';
import { Offer } from '@models/offer';

@Component({
  selector: 'app-offer-tour-details',
  templateUrl: './tour-details.component.html',
  styleUrls: ['./tour-details.component.less']
})
export class OfferTourDetailsComponent {

  @Input() offer: Offer;

}
