import { BaseService } from '@services/base/base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from '@enums/app-constants.enum';
@Injectable({
    providedIn: 'root'
})

/*
 * Base class for all other services. Sets some default values for all child services.
 * Also holds base configuration, depending on environment.
 */
export class HttpService {

    constructor(
        private baseService: BaseService,
        private http: HttpClient
    ) { }
    /*
     * Base get method to send a GET request.
     */
    public get(path: string) {
        return this.http.get(this.baseService.getEnv().apiUrl + path, this.setRequestHeaders());
    }

    /*
     * Base post method to send a POST request.
     */
    public post(path: string, body: any, setHeaders = true) {
        return this.http.post(this.baseService.getEnv().apiUrl + path, body, this.setRequestHeaders(setHeaders));
    }

    /*
     * Base put method to send a PUT request.
     */
    public put(path: string, body: any) {
        return this.http.put(this.baseService.getEnv().apiUrl + path, body, this.setRequestHeaders());
    }

    /*
     * Base patch method to send a PATCH request.
     */
    public patch(path: string, body: any) {
        return this.http.patch(this.baseService.getEnv().apiUrl + path, body, this.setRequestHeaders());
    }

    /*
     * Base delete method to send a PUT request.
     */
    public delete(path: string) {
        return this.http.delete(this.baseService.getEnv().apiUrl + path, this.setRequestHeaders());
    }

    /*
     * Retrieve current request settings. Consists of headers, request method.
     */
    public setRequestHeaders(setHeaders = true): any {
        const token = this.baseService.getCookie(AppConstants.TOKEN);
        if (token) {
            let headers = new HttpHeaders().set('Authorization', token)
            return {
                headers: headers,
            }
        } else {
            return {
                headers: new HttpHeaders()
            }
        }
    }

}
