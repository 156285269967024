<div class="modal-header">
  <div class="w-100">
    <i class="fa fa-map-signs mb-4" aria-hidden="true"></i>
    <h2 class="modal-title text-center">
      {{ 'byt_modal_entity_not_available_title' | translate }}
    </h2>
    <p
      class="text-center mt-3"
      [innerHTML]="
        'byt_modal_entity_not_available_description' | translate
      "></p>
    <div class="grid-modal-buttons mt-4">
      <button
        class="btn btn-md btn-orange"
        [routerLink]="['/']"
        (click)="bsModalRef.hide()">
        {{ 'byt_modal_entity_not_available_button_home' | translate }}
      </button>
      <a
        [routerLink]="['/trails']"
        (click)="bsModalRef.hide()"
        class="btn btn-outline-black border-dark btn-md text-dark text-uppercase"
        >{{ 'byt_modal_entity_not_available_button_similar' | translate }}</a
      >
    </div>
  </div>
</div>
