import { Component, Input } from '@angular/core';
import { BaseComponent } from '@components/base/base.component';
import { Offer } from '@models/offer';

@Component({
  selector: 'app-offer-legal-info',
  templateUrl: './legal-info.component.html',
  styleUrls: ['./legal-info.component.less']
})
export class OfferLegalInfoComponent extends BaseComponent {

  @Input() offer: Offer;
  @Input() isBooking: boolean = false;

}
