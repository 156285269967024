// modules
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
// components
import { BookingPaymentSidebarComponent } from './booking/payment-sidebar/payment-sidebar.component';
import { CheckboxesComponent } from './checkout/checkboxes/checkboxes.component';
import { NoFeeInfoComponent } from './checkout/no-fee-info/no-fee-info.component';
import { PaymentInfoComponent } from './checkout/payment-info/payment-info.component';
import { StdInfoComponent } from './checkout/std-info/std-info.component';
import { CheckoutSidebarComponent } from './checkout-sidebar/checkout-sidebar.component';
import { ClientFormComponent } from './client-form/client-form.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { CornerIconComponent } from './corner-icon/corner-icon.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HeaderImageComponent } from './header-image/header-image.component';
import { InfoButtonComponent } from './info-button/info-button.component';
import { InquiryModalComponent } from './inquiry-modal/inquiry-modal.component';
import { OfferBaseDataComponent } from './offers/base-data/base-data.component';
import { OfferBaseDataPrintComponent } from './offers/base-data-print/base-data-print.component';
import { OfferLegalInfoComponent } from './offers/legal-info/legal-info.component';
import { OfferTourDetailsComponent } from './offers/tour-details/tour-details.component';
import { OfferTrailInfoComponent } from './offers/trail-info/trail-info.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { SubmitButtonComponent } from './submit-button/submit-button.component';
import { TourDaysComponent } from './tour-days/tour-days.component';
import { TourPricesComponent } from './tour-prices/tour-prices.component';
import { TourServicesComponent } from './tour-services/tour-services.component';
import { TrustboxFooterComponent } from './trustbox-footer/trustbox-footer.component';

const COMPONENTS = [
  BookingPaymentSidebarComponent,
  CheckboxesComponent,
  CheckoutSidebarComponent,
  ClientFormComponent,
  ConfirmModalComponent,
  CornerIconComponent,
  FooterComponent,
  HeaderComponent,
  HeaderImageComponent,
  InfoButtonComponent,
  InquiryModalComponent,
  NoFeeInfoComponent,
  OfferBaseDataComponent,
  OfferBaseDataPrintComponent,
  OfferLegalInfoComponent,
  OfferTourDetailsComponent,
  OfferTrailInfoComponent,
  PaymentInfoComponent,
  SearchBarComponent,
  SearchInputComponent,
  SpinnerComponent,
  StdInfoComponent,
  SubmitButtonComponent,
  TourDaysComponent,
  TourPricesComponent,
  TourServicesComponent,
  TrustboxFooterComponent
];

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    PipesModule,
    LazyLoadImageModule,
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot()
  ],
  declarations: [...COMPONENTS],
  exports: [
    ...COMPONENTS,
    CommonModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class ComponentsModule {}
