/*
* The search component holds logic to search for items within a list.
*/

import { Component, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { BaseComponent } from '@components/base/base.component';

@Component({
    selector: 'app-search-input',
    templateUrl: 'search-input.component.html',
    styleUrls: ['search-input.component.less']
})
export class SearchInputComponent extends BaseComponent {

    // declaration of fields
    @Output() onSearch = new EventEmitter<string>();
    @Output() onClear = new EventEmitter<string>();
    @ViewChild('search') search: ElementRef;

    /*
    * Emitting search query to parent
    */
    public emitSearch(query: string) {
        this.onSearch.emit(this.search.nativeElement.value);
    }

    /*
    * Resetting input field
    */
    public resetSearch(){
        this.search.nativeElement.value = '';
        this.onClear.emit();
    }

}