import { Component, OnInit } from '@angular/core';
import { TrailMonths } from '@enums/trail-months.enum';
import { BaseComponent } from '@components/base/base.component';
import { BaseService } from '@services/base/base.service';
import { DestinationsService } from '@services/destinations/destinations.service';
import { forkJoin } from 'rxjs';
import { ActivitiesService } from '@services/activities/activities.service';
import { Destination } from '@models/destinations';
import { Activity } from '@models/activity';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.less']
})
export class SearchBarComponent extends BaseComponent implements OnInit {

  public isShown: boolean = false;
  public months: string[];
  public selectedCategory: any = {
    name: 'byt_activities',
    slug: 'byt_activities'
  };
  public selectedDestination: any = {
    name: 'byt_destinations',
    slug: 'byt_destinations'
  };
  public selectedMonth: string = 'month';

  constructor(
    public baseService: BaseService,
    private activitiesService: ActivitiesService,
    private destinationsService: DestinationsService
  ) {
    super(baseService);
    this.months = Object.values(TrailMonths);
  }

  ngOnInit() {
    const fields = 'name,claim,description,advantages,image_landscape,image_portrait,slug,classes,translations.*';
    this.item = forkJoin([this.destinationsService.get(fields), this.activitiesService.get(fields)]);
    if (this.baseService.getData('month')) {
      this.selectedMonth = this.baseService.getData('month').name;
    }
  }

  public toggleShow() {
    this.isShown = !this.isShown;
  }

  public selectDestination(destination: Destination) {
    this.selectedDestination = { slug: destination.slug, name: this.baseService.getDirectusText(destination, 'name') };
  }

  public selectCategory(category: Activity) {
    this.selectedCategory = { slug: category.slug, name: this.baseService.getDirectusText(category, 'name') };
  }

  public selectMonth(month: string | null, index?: number) {
    if (month) {
      this.selectedMonth = month;
      this.baseService.setData('month', month ? { name: month, value: index } : null);
    } else {
      this.selectedMonth = 'month';
      this.baseService.removeData('month');
    }
  }

  public onSubmit() {
    let url = '/trails/';

    if (this.selectedCategory.slug !== 'byt_activities' && this.selectedDestination.slug !== 'byt_destinations') {
      url = url.concat(this.selectedDestination.slug.toLowerCase() + '/' + this.selectedCategory.slug.toLowerCase());
    } else {
      if (this.selectedDestination.slug !== 'byt_destinations') {
        url = url.concat(this.selectedDestination.slug.toLowerCase());
      } else if (this.selectedCategory.slug !== 'byt_activities') {
        url = url.concat(this.selectedCategory.slug.toLowerCase());
      } else {
        // if no category or destinations has been selected, set weitwandern as default category
        url = '/trails/weitwandern';
      }
    }
    this.baseService.router.navigate([url], { replaceUrl: true });
  }
}
