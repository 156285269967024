/* eslint-disable @typescript-eslint/naming-convention */
/*
 * The clie form component is used to construt a form for client data.
 */

import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@components/base/base.component';
import { ClientTypes } from '@enums/client-types.enum';
import { BaseService } from '@services/base/base.service';

@Component({
  selector: 'app-client-form',
  templateUrl: 'client-form.component.html',
  styleUrls: ['client-form.component.less']
})
export class ClientFormComponent extends BaseComponent {
  @Input() public step: number;
  public clientFormGroup: FormGroup;

  constructor(
    public baseService: BaseService,
    private formBuilder: FormBuilder
  ) {
    super(baseService);
  }

  public createGroup(): FormGroup {
    this.clientFormGroup = this.formBuilder.group({
      address: this.formBuilder.group({
        apartment_number: null,
        building: null,
        city: null,
        country: null,
        country_code: null,
        floor: null,
        formatted_address: null,
        latitude: null,
        longitude: null,
        name: null,
        region: null,
        street: null,
        street_number: null,
        types: [['street_address']],
        zip: null
      }),
      email: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      locale: this.baseService.translate.getBrowserLang(),
      mobile: '',
      marketing_agreed: [false],
      type: ClientTypes.B2C,
      notes: ['']
    });
    return this.clientFormGroup;
  }
}
