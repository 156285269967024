<div class="modal-header pt-4">
  <h2 class="modal-title text-center">
    {{ 'support_inquiry' | translate }}
  </h2>
  <button
    type="button"
    class="btn-close close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<form
  (ngSubmit)="onSubmit()"
  [formGroup]="form"
  submit-form
  class="px-md-3 pb-3">
  <div class="modal-body py-1 px-0 px-md-4 mx-4">
    <!-- fname + lname -->
    <div class="form-group row mb-3">
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
        <label class="col-form-label text-xs-right"
          >{{ 'name' | translate }}*</label
        >
      </div>
      <div class="ol-xs-12 col-sm-12 col-md-10 col-lg-10">
        <input
          class="form-control"
          type="text"
          placeholder="Max Mustermann"
          formControlName="name"
          placeholder="{{ 'name_placeholder' | translate }}"
          required />
      </div>
    </div>
    <!--email -->
    <div class="form-group row mb-3">
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
        <label class="col-form-label text-xs-right"
          >{{ 'email' | translate }}*</label
        >
      </div>
      <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
        <input
          type="email"
          class="mb-1 form-control"
          formControlName="email"
          placeholder="{{ 'byt_email_placeholder' | translate }}"
          required
          pattern="[^@\s]+@[^@\s]+\.[^@\s]+" />
      </div>
    </div>
    <!-- phone -->
    <div class="form-group row mb-3">
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
        <label class="col-form-label text-xs-right"
          >{{ 'phone' | translate }}*</label
        >
      </div>
      <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
        <input
          type="tel"
          class="mb-1 form-control"
          formControlName="phone"
          placeholder="{{ 'byt_mobile_placeholder' | translate }}"
          pattern="^[0-9 +\-]{5,20}$"
          required />
      </div>
    </div>
    <!-- notes -->
    <div class="form-group row mb-3">
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
        <label class="col-form-label text-xs-right"
          >{{ 'message' | translate }}*</label
        >
      </div>
      <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
        <textarea
          rows="8"
          type="text"
          formControlName="notes"
          class="form-control"
          placeholder="{{ 'byt_message_placeholder' | translate }}"></textarea>
      </div>
    </div>
    <!-- accept marketing -->
    <div class="form-group row mb-3">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <label class="form-check-label mb-0 d-flex centered-checkbox">
          <input
            class="form-check-input me-2 flex-shrink-0"
            formControlName="marketing_agreed"
            type="checkbox" />
          <span [innerHTML]="'byt_accept_marketing' | translate"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <app-submit-button
      [class]="'btn btn-orange'"
      [isLoading]="isLoading"
      [isDisabled]="isLoading"
      [text]="'submit'"></app-submit-button>
  </div>
</form>
