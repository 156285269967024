import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { BaseService } from '@services/base/base.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/*
 * AuthGuard is to used determine that user is logged in our not
 */
@Injectable()
export class AuthGuard implements CanActivate {
    constructor(public baseService: BaseService) { }
    canActivate(): Observable<boolean> {
        return this.baseService.isLoggedIn.pipe(
            map((state: boolean) => {
                if (state) {
                    return true;
                } else {
                    this.baseService.router.navigate(['/login']);
                    return false;
                }
            }),
            catchError((err) => {
                this.baseService.router.navigate(['/login']);
                return of(false);
            })
        );
    }
}
