/*
 * CustomHttpInterceptor is used to intercept http calls and for example display error messages.
 */
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';
import { BaseService } from '@services/base/base.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastType } from '@enums/toast-type.enum';
@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private baseService: BaseService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    return next.handle(request).pipe(
      catchError((response: any) => {
        if (response.error) {
          this.baseService.toastService.create({
            type: ToastType.ERROR,
            message: this.baseService.translate.instant(
              response.error.message || 'error_message_generic'
            )
          });
          // depending on error code execute specific action
          switch (response.error.code) {
            case 102:
              this.authService.logout();
              break;
          }
        }
        return throwError(response);
      })
    );
  }
}
