import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ToastOptions } from '@interfaces/toast-options.interface';
import { ToastType } from '@enums/toast-type.enum';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private toastrService: ToastrService
  ) { }

  create(options: ToastOptions): ActiveToast<any> | undefined {
    if (isPlatformBrowser(this.platformId)) {
      options.timeOut = 7000;
      switch (options.type) {
        case ToastType.ERROR:
          return this.toastrService.error(options.message, options.title, options);
        case ToastType.SUCCESS:
          return this.toastrService.success(options.message, options.title, options);
        case ToastType.WARNING:
          return this.toastrService.warning(options.message, options.title, options);
        default:
          return this.toastrService.info(options.message, options.title, options);
      }
    } else {
      return;
    }
  }
}
