/*
* GetText pipe is used to get the text in a specific language out of a multi-lang array (e.g. descriptions).
* Assumes that text object has the properties language and description.
*/
import { Pipe, PipeTransform } from '@angular/core';
import { BaseService } from '@services/base/base.service';

@Pipe({
    name: 'getText'
})

export class GetText implements PipeTransform {

    constructor(
        private baseService: BaseService
    ) {}
    /*
    * Returns specific translated text of a multi-lang object.
    */
    transform(texts: Array<Object>, lang: any, prop?: string): string {
        return this.baseService.getText(texts, prop);
    }
}