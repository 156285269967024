/*
 * Directive is used to trigger system printer
 */

import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appPrint]'
})
export class PrinterDirective {
  @HostListener('click', ['$event']) onClick() {
    window.print();
  }
}
