/*
 * The header image component holds several images and context specific data.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@components/base/base.component';

@Component({
  selector: 'app-header-image',
  templateUrl: './header-image.component.html',
  styleUrls: ['./header-image.component.less']
})
export class HeaderImageComponent extends BaseComponent {
  @Input() claim: string;
  @Input() ctaText: string;
  @Input() imageLandscape: string;
  @Input() imagePortrait: string;
  @Input() title: string;
  @Output() clickCta = new EventEmitter<any>();

  public onClickCta(): void {
    this.clickCta.emit();
  }
}
