/* eslint-disable @typescript-eslint/naming-convention */
/*
 * This component is used to render an info button.
 */

import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@components/base/base.component';
import { ToastType } from '@enums/toast-type.enum';
import { BaseService } from '@services/base/base.service';
import { InquiriesService } from '@services/inquiries/inquiries.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-inquiry-modal',
  templateUrl: './inquiry-modal.component.html',
  styleUrls: ['./inquiry-modal.component.less']
})
export class InquiryModalComponent extends BaseComponent {
  form: FormGroup;

  constructor(
    public baseService: BaseService,
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private inquiryService: InquiriesService
  ) {
    super(baseService);

    this.form = this.formBuilder.group({
      email: [null, Validators.required],
      marketing_agreed: [false],
      name: [null, Validators.required],
      notes: [null, Validators.required],
      phone: [null, Validators.required]
    });
  }

  public async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.isLoading = true;
      await this.inquiryService.post(this.form.getRawValue());
      this.bsModalRef.hide();
      this.isLoading = false;
      this.baseService.toastService.create({
        type: ToastType.SUCCESS,
        message: this.baseService.translate.instant('toast_inquiry_success')
      });
    }
  }
}
