/*
 * The header component holds several assets (logo), menus and navigation elements.
 */

import { Component, HostBinding, OnInit } from '@angular/core';
import { BaseComponent } from '@components/base/base.component';
import { AppConstants } from '@enums/app-constants.enum';
import { ToastType } from '@enums/toast-type.enum';
import { AuthService } from '@services/auth/auth.service';
import { BaseService } from '@services/base/base.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent extends BaseComponent implements OnInit {
  // Host binding used to set mobile-open class on host
  @HostBinding('class.mobile-open') public showSideNav: boolean;
  // declaration of fields
  public isCollapsed = false;
  public lang: string;

  constructor(
    public authService: AuthService,
    public baseService: BaseService
  ) {
    super(baseService);
    this.showSideNav = false;
  }

  public ngOnInit(): void {
    this.lang = this.baseService.translate.currentLang;
  }

  /*
   * Click handler when burger button is clicked to show the side navigation.
   */
  public onToggleSideNav(): void {
    this.showSideNav = !this.showSideNav;
  }

  /*
   * Click handler when client wants to logout. Will use authService to logout.
   */
  public logout(): void {
    this.authService.logout();
    this.baseService.toastService.create({
      type: ToastType.SUCCESS,
      message: this.baseService.translate.instant('toast_logout_success')
    });
  }

  /*
   * Sets language with translateService
   */
  public setLang(lang: string): void {
    this.lang = lang;
    this.baseService.translate.use(lang);
    this.baseService.setCookie(AppConstants.LANGUAGE, lang);
  }
}
