import { Component, Input } from '@angular/core';
import { Trail } from '@models/trail';

@Component({
  selector: 'app-std-info',
  templateUrl: './std-info.component.html',
  styleUrls: ['./std-info.component.less']
})
export class StdInfoComponent {

  @Input() trail: Trail;

}
