/*
 * Directive is used to trigger inquiry modal component.
 */

import { Directive, HostListener } from '@angular/core';
import { InquiryModalComponent } from '@components/inquiry-modal/inquiry-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Directive({
  selector: '[appInquiryModal]'
})
export class InquiryModalDirective {
  constructor(private modalService: BsModalService) {}

  @HostListener('click', ['$event']) onClick($event: Event) {
    $event.preventDefault();
    this.modalService.show(InquiryModalComponent);
  }
}
