/*
 * This component is used to render an info button.
 */

import { Component } from '@angular/core';

@Component({
  selector: 'app-info-button',
  templateUrl: './info-button.component.html',
  styleUrls: ['./info-button.component.less']
})
export class InfoButtonComponent {}
