import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Directus, TypeMap } from '@directus/sdk';
import { DirectusCollections } from '@enums/directus-collections.enum';
import { environment } from '@environment/environment';
import { BaseService } from '@services/base/base.service';
import { from, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

/*
 * Base class for all directus related services. 
 */
export class DirectusService {

    public directus: Directus<TypeMap>;

    constructor(
        private baseService: BaseService,
        private transferState: TransferState
    ) {
        this.directus = new Directus(environment.directusUrl);
    }

    /*
    / * Method is used to fetch a specific item from directus
    */
    public getItem(collection: DirectusCollections, id: string, fields?: any, filter?: any) {
        const params = {
            fields: [(fields) ? fields : '*'],
            filter: filter ? filter : { status: { _eq: 'published' }}
        }
        return this.fetch(collection, `${collection}_${id}`, params);
    }

    /*
    / * Method is used to fetch multiple items form directus
    */
    public getItems(collection: DirectusCollections, fields?: any, sort?: any, filter?: any) {
        return this.fetch(collection, collection, {
            fields: [(fields) ? fields : '*'],
            sort: [(sort) ? sort : ''],
            filter: {
                status: {
                    _eq: 'published'
                }
            }
        });
    }

    /*
    / * Method is used to fetch data from directus and make use of state transfer on server side to pass data down to client, if already fetched
    */
    private fetch(collection: DirectusCollections, key: any, params: any) {
        const DATA_KEY = makeStateKey<any>(key);
        
        if (this.transferState.hasKey(DATA_KEY)) {
            const data = this.transferState.get<any>(DATA_KEY, null);
            this.transferState.remove(DATA_KEY);
            return of(data);
        } else {
            return from(this.directus.items(collection).readMany(params).catch((error: HttpErrorResponse) => this.catchError(error))).pipe(
                map((response: any) => {
                    if (this.baseService.isServer() && response) {
                        this.transferState.set(DATA_KEY, response.data);
                    }
                    return (response) ? response.data : [];
                }));
        }
    }

    /*
    / * Method is used to catch directus http error, parse error response and display message and remove loading spinner
    */
    public catchError(directusError: HttpErrorResponse) {
        console.log('🚀 ~ file: directus.service.ts ~ line 28 ~ DirectusService ~ catchError ~ directusError', directusError);
    }

}
