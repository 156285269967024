<ng-container *ngIf="trail">
    <!-- std-info -->
    <div class="row">
        <div class="col-12">
            <p class="text-small">
                {{'byt_stdinfo_1' | translate}}<br>
                {{'byt_stdinfo_2' | translate}}<br>
                {{'byt_stdinfo_3' | translate}}<br>
                {{'byt_stdinfo_4' | translate}} <a [routerLink]="['/contents/stdinfo']"
                    target="_blank">{{'byt_stdinfo'|translate}}</a>
            </p>
        </div>
    </div>
    <!-- help needed? -->
    <div class="mb-5 mt-2">
        <h3 class="mb-2">{{'byt_planner_help' | translate}}</h3>
        <p class="text-gray">{{'byt_planner_help_text' | translate}}</p>
        <p>
            <a *ngIf="trail?.owner?.phone" href="{{'tel:'+trail.owner?.phone}}"><i class="fa fa-phone"></i>
                {{ trail.owner.phone }}</a>
        </p>
    </div>
</ng-container>