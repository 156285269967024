export const environment = {
  apiUrl: 'https://capi.bookyourtrail.com',
  directusUrl: 'https://admin.bookyourtrail.com',
  debug: false,
  engineUrl: 'https://engine.trail-manager.com',
  hostname: 'https://www.bookyourtrail.com',
  imagesUrl: 'https://capi.bookyourtrail.com/images/',
  production: true,
  sentryDsn:
    'https://3108cce15f0645bb9ef8b469fde16f8c@o59683.ingest.sentry.io/6259580',
  stripeKey: 'pk_live_LqY6MZGbfv5XWTCJHh5jAqap',
  version: '1.4.4'
};
