/*
* CurrencyFormat is pipe used to format numbers for specific currencies.
*/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencyFormat'
})
export class CurrencyFormat implements PipeTransform {

    /*
    * Formats number to currency, depending on passed options. If no options were passed, default options are used.
    */
    transform(value: number, locale?: string, currencyCode?: string, digits?: number): string {
        let formatter = new Intl.NumberFormat(locale || 'de-DE', {
            style: 'currency',
            currency: currencyCode || 'EUR',
            minimumFractionDigits: digits || 2,
        });
        return (formatter.format(value) !== 'NaN') ? formatter.format(value) : '';
    }
}
