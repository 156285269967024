<ng-container *ngIf="offer">
  <div class="page-break-after">
    <h3 class="mb-3">{{ 'byt_overview' | translate }}</h3>
    <p class="text-gray">{{ 'byt_additionalInfoText' | translate }}</p>

    <ul class="list-orange list-orange-icon">
      <li>
        <a [routerLink]="['/trail', offer.trail?.slug]" target="_blank">{{
          'byt_servicesDescription' | translate
        }}</a>
      </li>
      <li>
        <a
          [routerLink]="['/trail', offer.trail?.slug, 'stages']"
          target="_blank"
          >{{ 'byt_stages_descriptions' | translate }}</a
        >
      </li>
      <li>
        <a
          [routerLink]="['/trail', offer.trail?.slug, 'providers']"
          target="_blank"
          >{{ 'accommodations' | translate }}</a
        >
      </li>
    </ul>

    <!-- note / call to action -->
    <div
      *ngIf="showContact"
      class="row justify-content-center alert alert-grey mt-5 mb-4 px-5 px-md-0 pt-3 d-block text-center"
      role="alert">
      <div class="col-12 offset-md-3 col-md-6 px-0">
        <i class="fa fa-info-circle"></i><br />
        <h3 class="ps-0">{{ 'byt_offer_contact_title' | translate }}</h3>

        <p>
          {{ 'byt_offer_contact_text1' | translate }}
          <a href="{{ mailTo }}">{{ offer.trail?.owner.email }}</a>
          {{ 'byt_offer_contact_text2' | translate }}
          <a href="tel:{{ offer.trail?.owner.phone }}">{{
            offer.trail?.owner.phone
          }}</a
          ><br />
          {{ 'byt_offer_book_text1' | translate }}
          {{ offer.code }}, {{ 'byt_offer_book_text2' | translate }}
        </p>
      </div>
    </div>
  </div>
</ng-container>
