import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AddDays } from './add-days/add-days.pipe';
import { CurrencyFormat } from './currency/format.pipe';
import { DaterangeCalendar } from './daterange/daterange.pipe';
import { GetImageUrl } from './get-image-url/get-image-url.pipe';
import { GetImageUrlDirectus } from './get-image-url-direcuts/get-image-url-directus.pipe';
import { GetMap } from './get-map/get-map.pipe';
import { GetText } from './get-text/get-text.pipe';
import { GetTextDirectus } from './get-text-directus/get-text-directus.pipe';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { SafeUrl } from './safe-url/safe-url.pipe';

// Pipes
const PIPES = [
  AddDays,
  CurrencyFormat,
  DaterangeCalendar,
  GetImageUrl,
  GetImageUrlDirectus,
  GetMap,
  GetText,
  GetTextDirectus,
  SafeHtmlPipe,
  SafeUrl
];

@NgModule({
  imports: [CommonModule],
  declarations: [...PIPES],
  exports: [...PIPES]
})
export class PipesModule {}
