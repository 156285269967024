import { Component, Input } from '@angular/core';
import { Offer } from '@models/offer';

@Component({
  selector: 'app-offer-base-data',
  templateUrl: './base-data.component.html',
  styleUrls: ['./base-data.component.less']
})
export class OfferBaseDataComponent {
  @Input() created: string;
  @Input() isPreview: boolean;
  @Input() offer: Offer;
}
