/*
 * SocketService handles connection and communication between client (application) and engine.
 */

import { Injectable } from '@angular/core';
import { PlannerEvents } from '@enums/planner-events.enum';
import { BaseService } from '@services/base/base.service';
import { BehaviorSubject } from 'rxjs';
import * as io from 'socket.io-client';
// import { io, Socket } from "socket.io-client";

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  // declaration of fields
  private _channels: Array<string>;
  private _event: BehaviorSubject<Object>;
  private _isConnected: boolean;
  private _isReConnected: boolean;
  private _isActive: boolean;
  private _socket: any;
  private _url: string;

  constructor(public baseService: BaseService) {
    this._channels = [];
    this._event = new BehaviorSubject({});
    this._isActive = false;
    this._isConnected = false;
    this._isReConnected = false;
    this._socket = null;
    this._url = this.baseService.getEnv().engineUrl;
  }

  get Channels() {
    return this._channels;
  }

  get Event() {
    return this._event.asObservable();
  }

  get isConnected() {
    return this._isConnected;
  }

  get isReConnected() {
    return this._isReConnected;
  }

  set isReConnected(val: boolean) {
    this._isReConnected = val;
  }

  get isActive() {
    return this._isActive;
  }

  set isActive(val: boolean) {
    this._isActive = val;
  }

  get Socket() {
    return this._socket;
  }

  public getPriceObject(): Object {
    return {
      additional: null,
      commission_custom: null,
      details: [],
      notes: null,
      price: null,
      price_custom: null,
      reduction: null,
      total: null,
      total_per_person: null
    };
  }

  /*
   * This method will send the initial request data like date, stages, trail to the planning engine. It is using the emit function of socket.io
   * to fire an event and notify the engine.
   */
  public sendRequestForm(data: any): void {
    console.log(
      '🚀 ~ file: socket.service.ts:86 ~ SocketService ~ sendRequestForm ~ data:',
      data
    );
    // console.log('socket service sendRequestform')
    this._socket.emit(PlannerEvents.request_form, data);
  }

  /*
   * This method will inform the engine to open a new channel, because a new day/stage for the planned tour is added.
   */
  public addDay(data: any): void {
    // add one day to date
    const tempDate = new Date(data.date);
    tempDate.setDate(tempDate.getDate() + 1);

    this._socket.emit(PlannerEvents.add_accommodation_channel, {
      board: data.board,
      category: data.category,
      date: tempDate.toISOString().substr(0, 10),
      stage: data.stage
    });
  }

  /*
   * This method will inform the engine to close a specfic channel, because a day/stage of the planned tour has been removed.
   */
  public deleteDay(id: any): void {
    this._socket.emit(PlannerEvents.delete_accommodation_channel, {
      id
    });
  }

  public getPrices(): void {
    this._socket.emit(PlannerEvents.get_prices);
  }

  public getChannels(): void {
    this._socket.emit(PlannerEvents.get_channels);
  }

  public resetPrices(): void {
    this._event.next({
      name: PlannerEvents.reset_prices,
      data: null
    });
  }

  public getRequestData(): void {
    // console.log('socket.service getRequestData')
    this._socket.emit(PlannerEvents.get_request_form);
  }

  public saveOffer(data: any): void {
    this._socket.emit(PlannerEvents.save_offer, data);
  }

  /*
   * In this method all callbacks and errorHandlers are registered. Is called immediatley after the connection has been established with the socket of the engine.
   */
  public registerEvents(): void {
    // first register to error events
    this._socket.on(
      PlannerEvents.angel_invalid_overwrite_price,
      this.errorHandler.bind(this)
    );
    this._socket.on(
      PlannerEvents.channel_not_found,
      this.errorHandler.bind(this)
    );
    this._socket.on(
      PlannerEvents.channels_not_valid,
      this.onChannelsNotValid.bind(this)
    );
    this._socket.on(
      PlannerEvents.delete_accommodation_channel_not_found,
      this.errorHandler.bind(this)
    );
    this._socket.on(
      PlannerEvents.invalid_add_accommodation_channel_data,
      this.errorHandler.bind(this)
    );
    this._socket.on(
      PlannerEvents.invalid_delete_accommodation_channel,
      this.onChannelDeletedError.bind(this)
    );
    this._socket.on(
      PlannerEvents.invalid_delete_accommodation_channel_data,
      this.onChannelDeletedError.bind(this)
    );
    this._socket.on(
      PlannerEvents.invalid_request,
      this.errorHandler.bind(this)
    );
    this._socket.on(
      PlannerEvents.invalid_save_offer,
      this.errorHandler.bind(this)
    );
    this._socket.on(
      PlannerEvents.mobility_invalid_overwrite_price,
      this.errorHandler.bind(this)
    );
    this._socket.on(
      PlannerEvents.offer_not_saved,
      this.errorHandler.bind(this)
    );
    // register other events
    this._socket.on(
      PlannerEvents.accommodation_channel_added,
      this.onChannelAdded.bind(this)
    );
    this._socket.on(
      PlannerEvents.accommodation_channel_deleted,
      this.onChannelDeleted.bind(this)
    );
    this._socket.on(
      PlannerEvents.angel_overwrite_price_done,
      this.getPrices.bind(this)
    );
    this._socket.on(
      PlannerEvents.request_form_data,
      this.onRequestData.bind(this)
    );
    this._socket.on(PlannerEvents.channels, this.onChannels.bind(this));
    this._socket.on(PlannerEvents.connect, this.onConnection.bind(this));
    this._socket.on(
      PlannerEvents.mobility_overwrite_price_done,
      this.getPrices.bind(this)
    );
    this._socket.on(PlannerEvents.offer_saved, this.onOfferSaved.bind(this));
    this._socket.on(PlannerEvents.prices, this.onPrices.bind(this));
    this._socket.on(PlannerEvents.show_timeout, this.onShowTimeout.bind(this));
    this._socket.on(PlannerEvents.timeout, this.onTimeout.bind(this));
  }

  /*
   * This method will unregister all events.
   */
  public unregisterEvents(): void {
    // first register to error events
    this._socket.off(PlannerEvents.angel_invalid_overwrite_price);
    this._socket.off(PlannerEvents.channel_not_found);
    this._socket.off(PlannerEvents.channels_not_valid);
    this._socket.off(PlannerEvents.delete_accommodation_channel_not_found);
    this._socket.off(PlannerEvents.invalid_add_accommodation_channel_data);
    this._socket.off(PlannerEvents.invalid_delete_accommodation_channel);
    this._socket.off(PlannerEvents.invalid_delete_accommodation_channel_data);
    this._socket.off(PlannerEvents.invalid_request);
    this._socket.off(PlannerEvents.invalid_save_offer);
    this._socket.off(PlannerEvents.mobility_invalid_overwrite_price);
    this._socket.off(PlannerEvents.offer_not_saved);
    // register other events
    this._socket.off(PlannerEvents.accommodation_channel_added);
    this._socket.off(PlannerEvents.accommodation_channel_deleted);
    this._socket.off(PlannerEvents.angel_overwrite_price_done);
    this._socket.off(PlannerEvents.request_form_data);
    this._socket.off(PlannerEvents.channels);
    this._socket.off(PlannerEvents.connect);
    this._socket.off(PlannerEvents.mobility_overwrite_price_done);
    this._socket.off(PlannerEvents.offer_saved);
    this._socket.off(PlannerEvents.prices);
    this._socket.off(PlannerEvents.show_timeout);
    this._socket.off(PlannerEvents.timeout);
  }

  /*
   * This method will connect to the client socket.
   */
  public connect(token: string): void {
    this._socket = io.connect(this._url + '/' + token, {
      path: '/socket.io'
    });
    this.registerEvents();
  }

  /*
   * This method will disconnect to the client socket, reset to initial data and inform subscribre.
   */
  public disconnect(save: boolean): void {
    // console.log('clientSocket disconnect and reset data', this._socket, save);
    if (this._socket !== null) {
      this._socket.emit(PlannerEvents.close, {
        save
      });

      this.unregisterEvents();
      this._socket.disconnect();
      this._channels = [];
      this._isActive = false;
      this._isConnected = false;
      this._isReConnected = false;
      this._socket = null;
    }

    this._event.next({
      name: PlannerEvents.disconnected,
      data: null
    });
  }

  /*
   * Is called when an error occurs during the socket is openend.
   */
  public errorHandler(message: any): void {
    // console.log('clientSocket errorHandler', message);
    this._event.next({
      name: PlannerEvents.error,
      data: message
    });
  }

  /*
   * Is called when an error occurs during deleting days/channels.
   */
  public onChannelDeletedError(message: any): void {
    this._event.next({
      name: PlannerEvents.channel_deleted_error,
      data: message
    });
  }

  /*
   * Listener for the event, which is emitted when the client socket connection has been established.
   */
  public onConnection(message: any): void {
    this._isConnected = true;
    // console.log('clientSocket onConnection', message, this, PlannerEvents.connect);
    this._event.next({
      name: PlannerEvents.connect,
      data: message
    });
  }

  public onChannelAdded(message: any): void {
    // console.log('clientSocket onChannelAdded', message);
    this._event.next({
      name: PlannerEvents.accommodation_channel_added,
      data: message
    });
  }

  public onChannelDeleted(message: any): void {
    // console.log('clientSocket onChannelDeleted', message);
    this._event.next({
      name: PlannerEvents.accommodation_channel_deleted,
      data: message
    });
  }

  /*
   * Listener for channels event. Is cahted after request form is submitted and channel data (days) has been generated by engine.
   */

  public onChannels(message: any): void {
    // console.log('clientSocket onChannels', message);
    // in case channels exists inform subscribers
    if (message.length) {
      this._channels = message;
      this._event.next({
        name: PlannerEvents.channels,
        data: message
      });
      // if channels array is empty, throw error e.g. stage order is wrong
    } else {
      this._event.next({
        name: PlannerEvents.channels_error,
        data: null
      });
      this.disconnect(false);
    }
  }

  public onChannelsNotValid(message: any): void {
    // console.log('clientSocket onChannelsNotValid', message);
    this._event.next({
      name: PlannerEvents.channels_not_valid,
      data: message
    });
  }

  /*
   * Listener for the event, which is emitted when socket timedout. Socket connection is closed then.
   */
  public onTimeout(message: any): void {
    // console.log('clientSocket onTimeout', message);
    this._event.next({
      name: PlannerEvents.timeout,
      data: message
    });
    this.disconnect(false);
  }

  /*
   * Listener for the event, which is emitted every second to update time on client.
   */
  public onShowTimeout(message: any): void {
    this._event.next({
      name: PlannerEvents.show_timeout,
      data: message
    });
  }

  public onPrices(message: any): void {
    // console.log('socketService onPrices', message);
    // event is used to inform multiple components
    this._event.next({
      name: PlannerEvents.prices,
      data: message
    });
  }

  public onRequestData(message: any): void {
    // console.log('socket service onRequestData', message);
    // event is used to inform multiple components
    this._event.next({
      name: PlannerEvents.request_form_data,
      data: message
    });
  }

  public onOfferSaved(message: any): void {
    // console.log(this._id + PlannerEvents.onPricesOverwritten', message);
    this._event.next({
      name: PlannerEvents.offer_saved,
      data: message
    });
  }

  /*
   * Method is calculating the num of adults, children and overall number of people inside a rooms array.
   */
  public getNumberOfPeople(rooms: any): any {
    let adults = 0;
    let children = 0;

    // calculate number of people
    for (const room of rooms) {
      adults += room.adults;
      children += room.children;
    }

    return {
      adults,
      children,
      people: adults + children
    };
  }
}
