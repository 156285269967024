import { Component } from '@angular/core';
import { BaseComponent } from '@components/base/base.component';
import { Package } from '@models/package';
import { Trail } from '@models/trail';
import { BaseService } from '@services/base/base.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-entity-unavailable-modal',
  templateUrl: 'entity-unavailable.component.html',
  styleUrls: ['entity-unavailable.component.less']
})
export class EntitityUnavailableModalComponent extends BaseComponent {
  public entity: Package | Trail;

  constructor(public baseService: BaseService, public bsModalRef: BsModalRef) {
    super(baseService);
  }
}
