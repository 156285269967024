<!-- HEADER -->
<div id="header">
  <!-- header nav -->

  <nav class="navbar navbar-dark navbar-expand-lg p-0">
    <div class="container">
      <a class="navbar-brand" [routerLink]="['/']">
        <img
          src="../../../assets/images/logos/bookyourtrail_grau.svg"
          width="200px"
          height="40px"
          alt="BookYourTrail® Logo" />
      </a>
      <div class="d-flex align-items-baseline">
        <ul class="navbar-contact navbar-nav d-lg-none">
          <li class="nav-item">
            <a
              class="nav-link"
              href="tel:{{ siteSettings.phone }}"
              target="_blank">
              <i class="fas fa-phone-alt"></i>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              appInquiryModal
              [attr.aria-label]="'byt_header_contact' | translate"
              [routerLink]="['./']">
              <i class="fa fa-envelope"></i>
            </a>
          </li>
          <span
            *ngIf="lang === 'de'"
            class="lang-switch"
            (click)="setLang('en')"
            >EN</span
          >
          <span
            *ngIf="lang === 'en'"
            class="lang-switch"
            (click)="setLang('de')"
            >DE</span
          >
        </ul>

        <button
          class="navbar-toggler"
          [attr.aria-label]="'byt_header_menu' | translate"
          type="button"
          (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed"
          aria-controls="navbarSupportedContent">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div
          class="collapse navbar-collapse flex-md-grow-0"
          [collapse]="!isCollapsed"
          id="navbarSupportedContent">
          <ul
            class="navbar-nav me-auto mb-2 mb-lg-0 container justify-content-end">
            <button
              class="navbar-toggler navbar-close"
              type="button"
              (click)="isCollapsed = !isCollapsed"
              [attr.aria-expanded]="!isCollapsed"
              aria-controls="navbarSupportedContent">
              <i class="fas fa-times-circle"></i>
            </button>
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="isCollapsed = !isCollapsed"
                [routerLink]="['./']"
                fragment="why"
                >{{ 'byt_why' | translate }}</a
              >
            </li>
            <!-- destinations -->
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="isCollapsed = !isCollapsed"
                [routerLink]="['./']"
                fragment="refDest"
                >{{ 'byt_destinations' | translate }}</a
              >
            </li>
            <!-- activities -->
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="isCollapsed = !isCollapsed"
                [routerLink]="['./']"
                fragment="refAct"
                >{{ 'byt_activities' | translate }}</a
              >
            </li>
            <!-- Aktuelles -->
            <li class="nav-item">
              <a
                class="nav-link"
                [href]="siteSettings.links[currLang]?.blog"
                target="_blank"
                >{{ 'byt_aktuelles' | translate }}</a
              >
            </li>
            <li class="nav-item d-none d-lg-block">
              <a
                class="nav-link"
                [href]="siteSettings.socialMedia.facebook"
                target="_blank">
                <i class="fa fa-facebook-f"></i>
              </a>
            </li>
            <li class="nav-item d-none d-lg-block">
              <a
                class="nav-link"
                [href]="siteSettings.socialMedia.instagram"
                target="_blank">
                <i class="fa fa-instagram"></i>
              </a>
            </li>
            <li class="nav-item d-none d-lg-block">
              <a
                class="nav-link"
                [attr.aria-label]="'byt_header_call' | translate"
                href="tel:{{ siteSettings.phone }}">
                <i class="fa fa-phone"></i>
              </a>
            </li>
            <li class="nav-item d-none d-lg-block">
              <a
                class="nav-link"
                [attr.aria-label]="'byt_header_contact' | translate"
                appInquiryModal
                [routerLink]="['./']">
                <i class="fa fa-envelope"></i>
              </a>
            </li>
            <!-- 
            <ng-container *ngIf="!isLoggedIn">
              <li class="nav-item">
                <a
                  class="nav-link"
                  (click)="isCollapsed = !isCollapsed"
                  [routerLink]="['/login']"
                  routerLinkActive="active">
                  <i class="fa fa-lock d-none d-lg-block"></i>
                  <span class="d-lg-none">{{ 'byt_logIn' | translate }}</span>
                </a>
              </li>
            </ng-container>
            <ng-container *ngIf="isLoggedIn">
              <li class="nav-item">
                <a class="nav-link" (click)="logout()" href>
                  <i class="fa fa-sign-out-alt d-none d-lg-block"></i>
                  <span class="d-lg-none">{{ 'byt_logOut' | translate }}</span>
                </a>
              </li>
            </ng-container> -->
            <li class="nav-item d-lg-none">
              <a
                class="nav-link"
                [href]="siteSettings.socialMedia.facebook"
                target="_blank">
                <i class="fa fa-facebook-f"></i>
              </a>
            </li>
            <li class="nav-item d-lg-none">
              <a
                class="nav-link"
                [href]="siteSettings.socialMedia.instagram"
                target="_blank">
                <i class="fa fa-instagram"></i>
              </a>
            </li>
            <div class="btn-group d-none d-lg-block" dropdown>
              <li
                class="nav-item dropdown-toggle"
                dropdownToggle
                [attr.aria-label]="'byt_header_lang_switch' | translate"
                aria-controls="dropdown-basic">
                <span
                  *ngIf="lang !== 'de'"
                  class="lang-switch"
                  (click)="setLang('en')"
                  >EN</span
                >
                <span
                  *ngIf="lang !== 'en'"
                  class="lang-switch"
                  (click)="setLang('de')"
                  >DE</span
                >
                <ul
                  id="dropdown-basic"
                  *dropdownMenu
                  class="dropdown-menu"
                  role="menu"
                  aria-labelledby="button-basic">
                  <li
                    *ngIf="lang === 'de'"
                    class="lang-switch"
                    (click)="setLang('en')">
                    EN
                  </li>
                  <li
                    *ngIf="lang === 'en'"
                    class="lang-switch"
                    (click)="setLang('de')">
                    DE
                  </li>
                </ul>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</div>
