/*
 * Displays the days of a booking/offer in table-like form.
*/
import { Component, Input } from '@angular/core';
import { BaseComponent } from '@components/base/base.component';
import { Offer } from '@models/offer';

@Component({
    selector: 'app-tour-days',
    templateUrl: 'tour-days.component.html',
    styleUrls: ['tour-days.component.less']
})
export class TourDaysComponent extends BaseComponent {
    @Input() offer: Offer;
}