/*
* This component is used to render a customizable submit button.
*/

import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-submit-button',
    templateUrl: './submit-button.component.html',
    styleUrls: ['./submit-button.component.less']
})

export class SubmitButtonComponent {

    @Input() class: string;
    @Input() isDisabled: boolean = false;
    @Input() isLoading: boolean = false;
    @Input() text: string;

}