<ng-container *ngIf="offer">
  <div class="row mb-3 mb-sm-2 px-2 text-center text-nightgreen d-print-none">
    <div class="col-md d-none d-print-block bg-grey p-5 mb-3 mb-md-0">
      <p class="mb-2">{{ 'byt_trail' | translate }}</p>
      <p class="text-big mb-0">{{ offer.trail?.name }}</p>
    </div>

    <div class="col-md bg-grey p-5 border-right mb-3 mb-md-0">
      <p class="mb-2" *ngIf="!isPreview">
        {{ 'byt_bookingCode' | translate }}:
      </p>
      <p class="mb-2" *ngIf="isPreview">{{ 'byt_offer_code' | translate }}:</p>
      <p class="text-big mb-0">{{ offer.code }}</p>
    </div>

    <div class="col-md d-none d-print-block bg-grey p-5 mb-3 mb-md-0">
      <p class="mb-2">{{ 'byt_bookingDate' | translate }}</p>
      <p class="text-big mb-0">{{ offer.created | date : 'dd.MM.yyyy' }}</p>
    </div>

    <div class="col-md bg-grey p-5 border-right mb-3 mb-md-0">
      <p class="mb-2">{{ 'byt_travelers' | translate }}</p>
      <p class="text-big mb-0">{{ offer.travelers.length }}</p>
    </div>

    <div class="col-md bg-grey p-5 mb-3 mb-md-0">
      <p class="mb-2">{{ 'byt_client' | translate }}</p>
      <p class="text-big mb-0">
        {{ offer.client?.firstname }} {{ offer.client?.lastname }}
      </p>
    </div>
  </div>

  <div class="row mb-3 mb-sm-2 mt-4" *ngIf="offer.client_email_message">
    <div class="col-xs-12 col-sm-4 col-md-3 col-lg-2">
      {{ 'byt_client_hint' | translate }}:
    </div>
    <div
      class="col-xs-12 col-sm-8 col-md-9 col-lg-10"
      [innerHTML]="offer.client_email_message"></div>
  </div>

  <div
    class="row mb-3 mb-sm-2 mt-4"
    *ngIf="offer.request?.animals?.number != null">
    <div class="col-xs-12 col-sm-4 col-md-3 col-lg-2">
      {{ 'byt_animals' | translate }}:
    </div>
    <div class="col-xs-12 col-sm-8 col-md-9 col-lg-10">
      {{ offer.request.animals.number }}
      ({{ offer.request.animals.description }})
    </div>
  </div>
</ng-container>
