/*
* InquiriesService is responsible to send request data to client-api.
*/

import { Injectable } from '@angular/core';
import { DirectusService } from '@services/directus/directus.service';
import { Inquiry } from '@models/inquiry';


@Injectable({
    providedIn: 'root'
})

export class InquiriesService {

    constructor(
        private directusService: DirectusService
    ) { }

    /*
    * Post inquiry to Api.
    */
    public async post(data: Inquiry): Promise<any> {
        const inquiries = this.directusService.directus.items('inquiries');
        return await inquiries.createOne(data);
    }
}
