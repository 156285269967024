import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '@environment/environment';
import * as Sentry from '@sentry/angular-ivy';

@Injectable()
export class BrowserErrorLogger implements ErrorHandler {
  constructor(@Inject(PLATFORM_ID) private readonly platformId: any) {
    if (isPlatformBrowser(this.platformId) && environment.production) {
      Sentry.init({
        dsn: environment.sentryDsn,
        release: environment.version,
        integrations: [
          new Sentry.BrowserTracing({
            tracingOrigins: ['localhost', 'https://www.bookyourtrail.com'],
            routingInstrumentation: Sentry.routingInstrumentation
          })
        ],
        tracesSampleRate: 0.3
      });
    }
  }

  handleError(error: any): void {
    if (isPlatformBrowser(this.platformId) && environment.production) {
      let errorToCapture = error;

      if (error instanceof HttpErrorResponse) {
        const errMsg = `${error.message} - URL: ${error.url} - Status: ${error.status} ${error.statusText}`;
        errorToCapture = new Error(errMsg);
      } else if (!(error instanceof Error)) {
        errorToCapture = new Error(JSON.stringify(error));
      }

      Sentry.captureException(
        errorToCapture.originalError || error.error || errorToCapture
      );
    }
  }
}
