<!-- payment info -->
<div class="row mt-4">
  <!-- Deposit -->
  <div class="col-12" *ngIf="isDeposit && !isFullPayment">
    <p class="mb-0">
      {{
        'payment_deposit_text'
          | translate
            : {
                deposit: depositPercentage,
                amount: depositAmount | currencyFormat
              }
      }}
      <br />
      {{
        'payment_deposit_restpayment_text'
          | translate
            : {
                amount: restPaymentAmount | currencyFormat,
                date: restPaymentDate | date: 'dd.MM.yyyy'
              }
      }}
    </p>
  </div>

  <!-- Deposit Complete -->
  <div class="col-12" *ngIf="isDeposit && isFullPayment">
    <p class="mb-0">
      {{
        'payment_deposit_complete'
          | translate
            : {
                amount: totalPrice | currencyFormat
              }
      }}
    </p>
  </div>

  <!-- restpayment -->
  <div class="col-12 text-left" *ngIf="!isDeposit">
    <p class="mb-0">
      {{
        'payment_restpayment_text'
          | translate: { amount: restPaymentAmount | currencyFormat }
      }}
    </p>
  </div>
</div>
