<div class="table-responsive-md">

  <table class="table table-borderless table-responsive">
    <thead>
      <tr class="bg-beige">
        <th>{{'date' | translate}}</th>
        <th>{{'stage' | translate}}</th>
        <th>{{'accommodation' | translate}}</th>
        <th>{{'category' | translate}}</th>
        <th>{{'rooms' | translate}}</th>
        <th>{{'board' | translate}}</th>
      </tr>
    </thead>

    <!-- channels & days (show if: tour details toggled, request form hidden) -->
    <!-- days -->
    <tr *ngFor="let accommodation of offer?.accommodation?.details; let i = index" class="border-bottom">
      <td class="circle-date"><span class="circle-number d-print-none">{{ i + 1 }}</span>
        {{ accommodation.date | date:'dd.MM.yyyy' }}</td>
      <td>
        <span *ngIf="offer?.mobility?.arrival_day == true && i == 0">
          {{ 'arrival_day' | translate }}
        </span>
        <span *ngIf="(!accommodation.isRestDay && i !== 0) || (offer?.mobility?.arrival_day == false && i == 0)">
          {{ accommodation.stage.code }}
        </span>
        <span *ngIf="accommodation.isRestDay">
          {{ 'rest_day' | translate }}
        </span>
      </td>

      <td>{{accommodation.selected_provider.provider.name}}</td>

      <td>{{accommodation.category | translate}}</td>

      <td>
        <div *ngFor="let room of accommodation.price.details">
          <span class="badge" [ngClass]="room.product?.type | lowercase">{{room.product?.type | translate}}</span><br>
          <span class="typeInfo text-xs"> {{ room.adults }} {{ 'adults' | translate }} | {{ room.children }}  {{ 'children' | translate }}
          </span>
        </div>
      </td>

      <td>{{accommodation.board | translate}}</td>
    </tr>
  </table>


</div>
