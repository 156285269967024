<nav class="navbar navbar-expand-lg navbar-dark">
  <div class="container justify-content-center">
    <ul class="navbar-nav mb-1 mt-1">
      <li class="nav-item" (click)="toggleShow()">
        <a class="nav-link find-trail" aria-current="page"
          ><i class="fas fa-search"></i> {{ 'byt_trail_finder' | translate }}</a
        >
      </li>
    </ul>
    <div
      class="d-flex flex-column flex-lg-row"
      *ngIf="isShown && (item | async) as data">
      <div class="btn-group" dropdown>
        <button
          id="button-destination"
          dropdownToggle
          type="button"
          class="btn btn-input dropdown-toggle"
          aria-controls="dropdown-destination">
          {{ selectedDestination.name | translate }} <span class="caret"></span>
        </button>
        <ul
          id="dropdown-destination"
          *dropdownMenu
          class="dropdown-menu"
          role="menu"
          aria-labelledby="button-destination">
          <li
            *ngFor="let destination of data[0]"
            (click)="selectDestination(destination)"
            role="menuitem">
            <a class="dropdown-item">{{
              destination | getTextDirecuts : 'name' : currLang
            }}</a>
          </li>
        </ul>
      </div>
      <div class="btn-group" dropdown>
        <button
          id="button-activity"
          dropdownToggle
          type="button"
          class="btn btn-input dropdown-toggle"
          aria-controls="dropdown-activity">
          {{ selectedCategory.name | translate }} <span class="caret"></span>
        </button>
        <ul
          id="dropdown-activity"
          *dropdownMenu
          class="dropdown-menu"
          role="menu"
          aria-labelledby="button-activity">
          <li
            *ngFor="let category of data[1]"
            (click)="selectCategory(category)"
            role="menuitem">
            <a class="dropdown-item">{{
              category | getTextDirecuts : 'name' : currLang
            }}</a>
          </li>
        </ul>
      </div>
      <div class="form-group mr-4" dropdown>
        <button
          id="button-months"
          dropdownToggle
          type="button"
          class="btn btn-input dropdown-toggle"
          aria-controls="dropdown-months">
          {{ selectedMonth | translate }} <span class="caret"></span>
        </button>
        <ul
          id="dropdown-months"
          *dropdownMenu
          class="dropdown-menu"
          role="menu"
          aria-labelledby="button-months">
          <li (click)="selectMonth(null)" role="menuitem">
            <a class="dropdown-item">{{
              'byt_trail_finder_all_year' | translate
            }}</a>
          </li>
          <li
            *ngFor="let month of months; let i = index"
            (click)="selectMonth(month, i)"
            role="menuitem">
            <a class="dropdown-item">{{ month | translate }}</a>
          </li>
        </ul>
      </div>
      <button class="btn btn-light" (click)="onSubmit()">
        {{ 'byt_home_1' | translate }}
      </button>
    </div>
  </div>
</nav>
