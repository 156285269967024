import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseService } from '@services/base/base.service';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

/*
 * Meta Service for SEO.
 */
export class MetaService {
  constructor(
    private baseService: BaseService,
    private meta: Meta,
    private router: Router,
    private title: Title,
    private translate: TranslateService,
    @Inject(DOCUMENT) private dom: any
  ) {}

  public initListener() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(async () => {
        let root = this.router.routerState.snapshot.root;

        while (root) {
          if (root.children && root.children.length) {
            root = root.children[0];
          } else if (root.data && root.data.title) {
            const routeTitle = root.data.title;
            await this.setRouteTags(routeTitle);
            return;
          } else {
            await this.setDefaultTags();
            this.setCanonicalURL(this.router.url);
            return;
          }
        }
      });
  }

  /*
   * Used to set custom tags based on page data
   */
  public async setPageTags(title: string, description?: string) {
    this.setTitle(
      `${title} - ${await this.translate.get('meta_title').toPromise()}`
    );
    if (description) {
      this.setDescription(description);
    }
  }

  /*
   * Used to set custom tags based on route data
   */
  private async setRouteTags(title: string) {
    this.setTitle(
      `${await this.translate.get(title).toPromise()} - ${await this.translate
        .get('meta_title')
        .toPromise()}`
    );
  }

  /*
   * Used to set global default tags
   */
  private async setDefaultTags() {
    // set default title
    this.setTitle(await this.translate.get('meta_title').toPromise());
    // set default description
    this.setDescription(
      await this.translate.get('meta_description').toPromise()
    );
  }

  /*
   * Used to set canonical url
   */
  private setCanonicalURL(url?: string) {
    const canonicalLink: HTMLLinkElement = this.dom.createElement('link');
    canonicalLink.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(canonicalLink);
    canonicalLink.setAttribute(
      'href',
      `${this.baseService.getEnv().hostname}${url}`
    );
  }

  /*
   * Used to set meta title
   */
  private setTitle(title: string) {
    this.title.setTitle(title);
    this.meta.updateTag({ name: 'title', content: title });
    this.meta.updateTag({ property: 'og:title', content: title });
  }

  /*
   * Used to set meta description
   */
  private setDescription(description: string) {
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ property: 'og:description', content: description });
  }
}
