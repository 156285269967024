/*
 * The footer component holds several menus and navigation elements.
 */

import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@components/base/base.component';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent extends BaseComponent implements OnInit {
  // declaration of fields
  public version: string;

  public ngOnInit(): void {
    this.version = this.baseService.getEnv().version;
  }

  public async onOpenCookieSettings($event: Event): Promise<void> {
    $event.preventDefault();
    await this.baseService.router.navigate([], {
      queryParams: { cmpscreen: '' },
      queryParamsHandling: 'merge'
    });
  }
}
