import { Directive, HostListener, Renderer2 } from '@angular/core';

/*
* Used to check if form has been submitted and add a specific class
*/


@Directive({
    selector: "[submit-form]"
})
export class SubmitFormDirective {

    constructor(
        private renderer: Renderer2
    ) { }

    @HostListener('submit', ['$event.target']) submitForm(el: HTMLElement) {
        this.renderer.addClass(el, 'is-submitted');
    }
}