/* eslint-disable no-underscore-dangle */
/*
 * TrailsService used for getting information about trails.
 */

import { Injectable } from '@angular/core';
import { TrailStatus } from '@enums/trail-status.enum';
import { Stage } from '@models/stage';
import { Trail } from '@models/trail';
import { EntitityUnavailableModalComponent } from '@pages/trail/components/entity-unavailable/entity-unavailable.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app.routes';

import { BaseService } from '../base/base.service';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class TrailsService {
  public trails: Trail[];
  public trail: Trail;

  // declaration of fields
  constructor(
    public baseService: BaseService,
    public httpService: HttpService,
    public modalService: BsModalService
  ) {}

  /*
   * Fetch trails from the API and parse data
   */
  public get(): Observable<Trail[]> {
    if (!this.trails) {
      return this.httpService.get('/trails').pipe(
        map((response: any) => {
          this.trails = response.data.sort(
            (a: Trail, b: Trail) =>
              (a.order != null ? a.order : Infinity) -
              (b.order != null ? b.order : Infinity)
          );
          return this.trails;
        }),
        publishReplay(1),
        refCount()
      );
    }
    return of(this.trails);
  }

  /*
   * Get trail by id from the API
   */
  public getById(id: string): Observable<Trail> {
    if (this.trail && (this.trail._id === id || this.trail.slug === id)) {
      return of(this.trail);
    } else {
      return this.httpService.get(`/trails/${id}`).pipe(
        map((response: any) => {
          this.trail = response.data;
          return response.data;
        })
      );
    }
  }

  /*
   * Filter list of trails by category
   */
  public filterByCategory(trails: Trail[], category: string) {
    return trails.filter((trail: Trail) => {
      return trail.category.toLowerCase() === category.toLowerCase();
    });
  }

  /*
   * Filter list of trails by destination
   */
  public filterByDestination(trails: Trail[], search: string) {
    return trails.filter((trail: Trail) => {
      const destinations = trail.configuration.destinations.filter(
        (destination: any) => {
          return (
            destination.name.toLowerCase() === search.toLowerCase() &&
            destination.value === true
          );
        }
      );
      return destinations.length ? true : false;
    });
  }

  /*
   * Filter list of trails by trail configuration
   */
  public filterByMonth(trails: Trail[], search: string) {
    return trails.filter((trail: Trail) => {
      const result = trail.configuration.months.filter((month: any) => {
        return (
          month.name.toLowerCase() === search.toLowerCase() &&
          month.value === true
        );
      });
      return result.length ? trail : null;
    });
  }

  /*
   * Will return a specific stage out of stages array based on stage id.
   */
  public filterStages(stages: [], id: string): any {
    const result: Stage[] = stages.filter((stage: any) => {
      return stage.stage._id === id;
    });
    // if no stage was found return empty objet to not break code in other places
    return result.length ? result[0].stage : {};
  }

  /*
   * Used to check if trail is draft or closed and redirect to 404 page.
   */
  public checkTrailStatus(trail: Trail): void {
    if (Object.keys(trail).length === 0) {
      this.baseService.router.navigate([AppRoutes.NotfoundPage], {
        replaceUrl: true
      });
    }

    switch (trail.status) {
      case TrailStatus.Draft:
        this.baseService.router.navigate([AppRoutes.NotfoundPage], {
          replaceUrl: true
        });
        break;
      case TrailStatus.Published:
      case TrailStatus.Private:
        break;
      case TrailStatus.Closed:
        // show modal with info
        this.modalService.show(EntitityUnavailableModalComponent, {
          ignoreBackdropClick: true
        });
        break;
    }
  }
}
