
export enum DirectusCollections {
    ACTIVITIES = 'activities',
    CONTENTS = 'contents',
    DESTINATIONS = 'destinations',
    OPERATORS = 'operators',
    PARTNERS = 'partners',
    SLIDES = 'slides',
    SUPPORTERS = 'supporters',
}
