/*
* GetTextDirectus pipe is used to get the text in a specific language out of a multi-lang array (e.g. descriptions).
* Assumes that text object has the properties language and description.
*/
import { Pipe, PipeTransform } from '@angular/core';
import { BaseService } from '@services/base/base.service';

@Pipe({
    name: 'getTextDirecuts'
})

export class GetTextDirectus implements PipeTransform {

    constructor(
        private baseService: BaseService
    ) {}
    /*
    * Returns specific translated text of a multi-lang object.
    * Lang param is only used to trigger change detection.
    */
    transform(item: any, prop: string, lang?: string): string {
        return this.baseService.getDirectusText(item, prop);
    }
}