/*
* GetImageUrlDirectus is a pipe used to get the url of an image for a specific environment.
*/
import { Pipe, PipeTransform } from '@angular/core';
import { BaseService } from '@services/base/base.service';

@Pipe({
    name: 'getImageUrlDirectus'
})
export class GetImageUrlDirectus implements PipeTransform {

    constructor(public baseService: BaseService) { }

    transform(value: string, quality?: number): string {
        return `${this.baseService.getEnv()['directusUrl']}/assets/${value}?quality=${(quality) ? quality : '70'}`;
    }
}
