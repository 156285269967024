/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable no-underscore-dangle */
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  Optional,
  PLATFORM_ID
} from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Enviroment } from '@interfaces/environment.interface';
import { environment } from '@environment/environment';
export const ENV = new InjectionToken<string>('ENV');
const envKey = makeStateKey('ENV');

@Injectable()
export class AppEnvService {
  // declarations of fields
  private _env: Enviroment;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(ENV) private envToken: InjectionToken<string>,
    private injector: Injector,
    private state: TransferState
  ) {}

  // This is the method you want to call at bootstrap
  // Important: It should return a Promise
  public init() {
    return new Promise((resolve, reject) => {
      const platform = isPlatformBrowser(this.platformId)
        ? 'in the browser'
        : 'on the server';

      if (isPlatformServer(this.platformId)) {
        this._env = this.injector.get(this.envToken)
          ? JSON.parse(JSON.stringify(this.injector.get(this.envToken)))
          : {};
        this.state.set(envKey, this._env as any);
      } else {
        this._env = JSON.parse(
          JSON.stringify(this.state.get(envKey, environment as any))
        );
        // if envKey is undefined fallback to environmet.ts for local development
        if (!Object.keys(this._env).length) {
          this._env = environment;
        }
      }

      // check if env is set correctly, if not, set it from environment.ts
      if (!this._env.production && !Object.keys(this._env).length) {
        this._env = environment;
      }

      resolve(this._env);
    });
  }

  /**
   * Retrieve environment configuration.
   */
  public getEnv(): Enviroment {
    return this._env;
  }

  /**
   * Method to retrieve info if platform is browser or server
   */
  public isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  /**
   * Method to retrieve info if platform is browser or server
   */
  public isServer(): boolean {
    return isPlatformServer(this.platformId);
  }
}

export function appEnvFactory(appEnvService: AppEnvService) {
  return () => appEnvService.init();
}
