/* eslint-disable @typescript-eslint/naming-convention */
export enum PlannerEvents {
  // general
  close = <any>'close_socket',
  connect = <any>'connect',
  error = <any>'error',
  ready = <any>'_ready',
  // general socket level
  authenticate = <any>'authenticate',
  disconnected = <any>'disconnected',
  namespace = <any>'namespace',
  // client socket level
  accommodation_channel_added = <any>'accommodation_channel_added',
  accommodation_channel_deleted = <any>'accommodation_channel_deleted',
  add_accommodation_channel = <any>'add_accommodation_channel',
  angel_invalid_overwrite_price = <any>'angel_invalid_overwrite_price',
  angel_overwrite_price = <any>'angel_overwrite_price',
  angel_overwrite_price_done = <any>'angel_overwrite_price_done',
  channel_not_found = <any>'channel_not_found',
  channels = <any>'channels',
  channels_error = <any>'channels_error',
  channels_not_valid = <any>'channels_not_valid',
  delete_accommodation_channel = <any>'delete_accommodation_channel',
  delete_accommodation_channel_not_found = <any>(
    'delete_accommodation_channel_not_found'
  ),
  get_channels = <any>'get_channels',
  get_prices = <any>'get_prices',
  get_request_form = <any>'get_request_form',
  invalid_add_accommodation_channel_data = <any>(
    'invalid_add_accommodation_channel_data'
  ),
  invalid_delete_accommodation_channel = <any>(
    'invalid_delete_accommodation_channel'
  ),
  invalid_delete_accommodation_channel_data = <any>(
    'invalid_delete_accommodation_channel_data'
  ),
  invalid_request = <any>'invalid_request',
  invalid_save_offer = <any>'invalid_save_offer',
  mobility_invalid_overwrite_price = <any>'mobility_invalid_overwrite_price',
  mobility_overwrite_price = <any>'mobility_overwrite_price',
  mobility_overwrite_price_done = <any>'mobility_overwrite_price_done',
  offer_saved = <any>'offer_saved',
  offer_not_saved = <any>'offer_not_saved',
  prices = <any>'prices',
  request_form = <any>'request_form',
  request_form_data = <any>'request_form_data',
  reset_prices = <any>'reset_prices',
  save_offer = <any>'save_offer',
  show_timeout = <any>'show_timeout',
  timeout = <any>'timeout',
  // listener on channel level
  channel_busy = <any>'_channel_busy',
  channel_deleted_error = <any>'channel_deleted_error',
  invalid_add_accommodation_channel = <any>'_invalid_add_accommodation_channel',
  invalid_overwrite_price = <any>'_invalid_overwrite_price',
  invalid_update_board = <any>'_invalid_update_board',
  invalid_update_category = <any>'_invalid_update_category',
  invalid_update_provider = <any>'_invalid_update_provider',
  overwrite_price_done = <any>'_overwrite_price_done',
  price = <any>'_price',
  provider_blocked = <any>'_provider_blocked',
  provider_unblockable = <any>'_provider_unblockable',
  providers = <any>'_providers',
  shift = <any>'_shift',
  // emitted events on channel level
  overwrite_price = <any>'_overwrite_price',
  update_board = <any>'_update_board',
  update_category = <any>'_update_category',
  update_provider = <any>'_update_provider',
  // client side events
  requestData = <any>'requestData'
}
