/*
 * GetMap is pipe used to get Google Maps for specific inputs
 */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getMap'
})
export class GetMap implements PipeTransform {
  /*
   * constructs Google Maps string, depending on passed Input
   */
  transform(accommodation: string, city?: string): string {
    const acc = accommodation.replace(/[^\w\s]/gi, '');
    const path = `<iframe src=\"https://maps.google.com/maps?q=${acc},${city}&hl=de&z=16&amp&output=embed\"width=\"100%\"height=\"200\"frameborder=\"0\"scrolling=\"no\"></iframe>`;
    return path;
  }
}
