<!-- tour total -->
<div class="row end-price mt-4">
  <div class="col-8">
    <span>{{ 'tour_total' | translate }}</span>
  </div>
  <div class="col-4 text-end">
    {{ offer?.total_price | currencyFormat }}
  </div>
</div>

<!-- total per person -->
<div class="row end-price-single text-gray mt-2 mb-2">
  <div class="col-8">
    <span class="lh1">{{ 'per_person' | translate }}</span>
  </div>
  <div class="col-4 text-end">
    {{ price_per_person | currencyFormat }}
  </div>
</div>
<hr />

<!-- not included services -->
<div class="row mt-4">
  <div class="col-12">
    <h3 class="mb-2">{{ 'not_included_services' | translate }}</h3>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <p class="text-gray m-0">
      {{
        offer?.trail?.descriptions?.not_included_services | getText : currLang
      }}
    </p>
  </div>
</div>
