import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.less']
})
export class PaymentInfoComponent {
  @Input() isDeposit: boolean;
  @Input() isFullPayment: boolean;
  @Input() depositPercentage: number;
  @Input() depositAmount: number;
  @Input() restPaymentAmount: number;
  @Input() restPaymentDate: Date;
  @Input() totalPrice: number;
}
