import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '404',
    loadChildren: () =>
      import('./pages/404/404.module').then(m => m.NotfoundPageModule)
  },
  {
    path: '503',
    loadChildren: () =>
      import('./pages/503/503.module').then(m => m.ErrorPageModule)
  },
  {
    path: 'bookings',
    loadChildren: () =>
      import('./pages/bookings/bookings.module').then(m => m.BookingsModule)
  },
  {
    path: 'contents',
    loadChildren: () =>
      import('./pages/contents/contents.module').then(m => m.ContentsModule)
  },
  {
    path: 'destinations',
    loadChildren: () =>
      import('./pages/contents/contents.module').then(m => m.ContentsModule)
  },
  {
    path: 'offers',
    loadChildren: () =>
      import('./pages/offers/offers.module').then(m => m.OffersModule)
  },
  {
    path: 'planner',
    loadChildren: () =>
      import('./pages/planner/planner.module').then(m => m.PlannerModule)
  },
  {
    path: 'trail',
    loadChildren: () =>
      import('./pages/trail/trail.module').then(m => m.TrailModule)
  },
  {
    path: 'trails',
    loadChildren: () =>
      import('./pages/trails/trails.module').then(m => m.TrailsModule)
  },
  {
    path: 'traveldoc',
    loadChildren: () =>
      import('./pages/traveldoc/traveldoc.module').then(m => m.TraveldocModule)
  },
  // unused client routes
  // {
  //   path: 'login',
  //   loadChildren: () =>
  //     import('./pages/login/login.module').then(m => m.LoginPageModule)
  // },
  // {
  //   path: 'lostpw',
  //   loadChildren: () =>
  //     import('./pages/lostpw/lostpw.module').then(m => m.LostpwPageModule)
  // },
  // {
  //   path: 'register',
  //   loadChildren: () =>
  //     import('./pages/register/register.module').then(m => m.RegisterPageModule)
  // },
  // {
  //   path: 'setpw',
  //   loadChildren: () =>
  //     import('./pages/setpw/setpw.module').then(m => m.SetpwPageModule)
  // },
  // {
  //   path: 'confirmmail',
  //   loadChildren: () =>
  //     import('./pages/confirm-mail/confirm-mail.module').then(
  //       m => m.ConfirmMailModule
  //     )
  // },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 130]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
