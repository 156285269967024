import { Component, OnInit } from '@angular/core';
import { AppConstants } from '@enums/app-constants.enum';
import { BaseService } from '@services/base/base.service';
import { MetaService } from '@services/meta/meta.service';
import { ServiceWorkerService } from '@services/service-worker/service-worker.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { deLocale } from 'ngx-bootstrap/locale';
defineLocale('de', deLocale);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  constructor(
    private baseService: BaseService,
    private metaService: MetaService,
    private localeService: BsLocaleService,
    private serviceWorkerService: ServiceWorkerService
  ) {}

  public ngOnInit(): void {
    // i18n
    // first set fallback lanugage
    this.baseService.translate.setDefaultLang('de');
    // get language from cookie or use default
    const lang = this.baseService.getCookie(AppConstants.LANGUAGE) || 'de';
    this.baseService.translate.use(lang);
    // initialize bs datepicker locales
    this.localeService.use('de');
    // initialize metaServcie
    this.metaService.initListener();
    // init service worker service
    this.serviceWorkerService.initListener();
  }
}
