import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appBackgroundImage]'
})
export class BackgroundImageDirective implements OnInit {
  @Input() backgroundImage: string;
  @Input() backgroundPosition: string;
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
  ngOnInit() {
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'background-image',
      `url(${this.backgroundImage})`
    );
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'background-position',
      this.backgroundPosition
    );
  }
}
