import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from '@components/base/base.component';
import { Trail } from '@models/trail';

@Component({
  selector: 'app-checkout-checkboxes',
  templateUrl: './checkboxes.component.html',
  styleUrls: ['./checkboxes.component.less']
})
export class CheckboxesComponent extends BaseComponent {
  @Input() trail: Trail;
  @Input() formGroup: FormGroup;
}
