/*
* AddDays is a pipe used to add a number of days to a date.
*/
import { Pipe, PipeTransform } from '@angular/core';
import { BaseService } from '@services/base/base.service';
import { addDays } from 'date-fns'

@Pipe({
    name: 'addDays'
})
export class AddDays implements PipeTransform {

    constructor(public baseService: BaseService) { }

    transform(date: string, days: number): Date {
        return addDays(new Date(date), days)
    }
}
