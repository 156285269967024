/*
 * Directive is used to hide or show specific component based on input.
 */

import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appShowIfTrue]'
})
export class ShowIfTrueDirective implements AfterViewInit {
  @Input() appShowIfTrue = false;

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    if (!this.appShowIfTrue) {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }
}
