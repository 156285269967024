import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '@components/base/base.component';
declare const window: any;

@Component({
  selector: 'app-trustbox-footer',
  templateUrl: './trustbox-footer.component.html',
  styleUrls: ['./trustbox-footer.component.less']
})
export class TrustboxFooterComponent extends BaseComponent implements OnInit {

  // declaration of fields
  @ViewChild('trustbox', { static: true }) trustbox: ElementRef;

  ngOnInit(): void {
    if (this.isBrowser && window && window.Trustpilot) {
      window.Trustpilot.loadFromElement(this.trustbox.nativeElement);
  }
  }

}
