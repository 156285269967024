/* eslint-disable @typescript-eslint/naming-convention */
/*
 * Compared to splitted component for planner, this component is justed used to display prices for bookings/offers after they have been created.
 */

import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@components/base/base.component';
import { Offer } from '@models/offer';
import { format, parseISO } from 'date-fns';

@Component({
  selector: 'app-tour-services',
  templateUrl: 'tour-services.component.html',
  styleUrls: ['tour-services.component.less']
})
export class TourServicesComponent extends BaseComponent implements OnInit {
  @Input() offer: Offer;
  @Input() ind_services: any;

  // declaration of fields and form
  checkExitLoop: number;
  public luggageMobilityServices: any[] = [];
  public shuttleMobilityServices = []; // TODO filter for shuttle
  public backTransferMobilityServices = []; // TODO filter for back transfer
  public individualMobilityServices: any[] = [];

  ngOnInit() {
    // construct data for easier display in template
    if (this.offer && Object.keys(this.offer).length > 0) {
      // luggage
      if (
        this.offer?.mobility?.luggage_price &&
        this.offer?.mobility?.luggage_price.total > 0 &&
        this.offer?.mobility?.luggage_price?.details
      ) {
        this.luggageMobilityServices = this.offer.mobility.luggage_price.details
          .sort((a: any, b: any) => a.day - b.day)
          .filter((service: any) => service.luggage_price > 0)
          .map((service: any) => {
            const transportData = {
              date: format(parseISO(service.day), 'dd.MM'),
              stage: null,
              from: null,
              to: null
            };
            this.offer.accommodation.details.forEach(
              (accommodation: any, index: number) => {
                if (
                  accommodation.date ===
                  format(parseISO(service.day), 'yyyy-MM-dd')
                ) {
                  transportData.stage = accommodation.stage.code;
                  const fromProvider = this.offer.accommodation.details[
                    index - 1
                  ].selected_provider?.provider?.luggage_transport
                    ? this.offer.accommodation.details[index - 1]
                        .selected_provider?.provider?.name
                    : this.offer.accommodation.details[index - 2]
                        .selected_provider?.provider?.name;
                  transportData.from = fromProvider;
                  const toProvider = accommodation.selected_provider?.provider
                    ?.luggage_transport
                    ? accommodation.selected_provider?.provider?.name
                    : this.offer.accommodation.details[index + 1]
                        .selected_provider?.provider?.name;
                  transportData.to = toProvider;
                }
              }
            );
            return transportData;
          });
      }
      if (this.ind_services && this.ind_services.length > 0) {
        this.individualMobilityServices = this.ind_services.filter(
          (service: any) =>
            service?.type === 'LuggageTransport' ||
            service?.type === 'PassengerTransport' ||
            service?.type === 'BackTransport' ||
            service?.type === 'Shuttle' ||
            service?.type === 'Mobility' ||
            service?.type === 'IndividualMobility'
        );
      }
    }
  }
}
