/*
* GetImageUrl is a pipe used to get the url of an image for a specific environment.
*/
import { Pipe, PipeTransform } from '@angular/core';
import { BaseService } from '@services/base/base.service';

@Pipe({
    name: 'getImageUrl'
})
export class GetImageUrl implements PipeTransform {

    constructor(public baseService: BaseService) { }

    transform(value: string): string {
        return this.baseService.getEnv()['imagesUrl'] + value;
    }
}
