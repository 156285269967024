<div class="modal-header">
  <h2 class="modal-title text-center">{{ title | translate }}</h2>
</div>

<div class="modal-body">
  <div class="row py-1 px-0 px-md-4">
    <div class="col-12 p-3 text-center">
      <p>{{ message | translate }}</p>
    </div>
  </div>
</div>

<div class="modal-footer">
  <app-submit-button [class]="'btn btn-secondary'" (click)="decline()" [text]="declineText" *ngIf="declineText && declineCallback"></app-submit-button>
  <app-submit-button [class]="'btn btn-orange'" (click)="confirm()" [text]="confirmText"></app-submit-button>
</div>