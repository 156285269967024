import { Component, Input } from '@angular/core';
import { Offer } from '@models/offer';

@Component({
  selector: 'app-offer-base-data-print',
  templateUrl: './base-data-print.component.html',
  styleUrls: ['./base-data-print.component.less']
})
export class OfferBaseDataPrintComponent {

  @Input() created: string;
  @Input() offer: Offer;

}
