<form [formGroup]="clientFormGroup" class="mt-4" submit-form>
  <!-- client data -->

  <div class="card-text py-1 px-0 px-md-3">
    <div class="container">
      <h3 class="text-nightgreen d-flex align-items-center mb-3">
        <span class="circle-number">{{ step }}</span>
        {{ 'byt_contact_data' | translate }}
      </h3>

      <!-- fname + lname -->
      <div class="form-group row mb-2 mb-md-3">
        <div class="col-xs-12 col-sm-4 col-md-3 mb-0">
          <label class="col-form-label text-xs-right"
            >{{ 'fname' | translate }}*</label
          >
        </div>
        <div class="col-xs-12 col-sm-8 col-md-3 mb-2 mb-md-0">
          <input
            class="form-control"
            type="text"
            placeholder="Max"
            formControlName="firstname"
            placeholder="{{ 'fname' | translate }}"
            required />
        </div>
        <div class="col-xs-0 col-md-1"></div>
        <div class="col-xs-12 col-sm-4 col-md-2">
          <label class="col-form-label text-xs-right"
            >{{ 'lname' | translate }}*</label
          >
        </div>
        <div class="col-xs-12 col-sm-8 col-md-3">
          <input
            class="form-control"
            type="text"
            placeholder="Mustermann"
            formControlName="lastname"
            placeholder="{{ 'lname' | translate }}"
            required />
        </div>
      </div>
      <!--email -->
      <div class="form-group row mb-2 mb-md-3">
        <div class="col-xs-12 col-sm-4 col-md-3 mb-0">
          <label class="col-form-label text-xs-right"
            >{{ 'email' | translate }}*</label
          >
        </div>
        <div class="col-xs-12 col-sm-8 col-md-3 mb-2 mb-md-0">
          <input
            type="email"
            class="mb-1 form-control"
            formControlName="email"
            placeholder="{{ 'byt_email' | translate }}"
            required
            pattern="[^@\s]+@[^@\s]+\.[^@\s]+" />
        </div>
        <div class="col-xs-0 col-md-1"></div>
        <div class="col-xs-12 col-sm-4 col-md-2">
          <label class="col-form-label text-xs-right"
            >{{ 'phone' | translate }}*</label
          >
        </div>
        <div class="col-xs-12 col-sm-8 col-md-3">
          <input
            type="tel"
            class="mb-1 form-control"
            formControlName="mobile"
            placeholder="{{ 'byt_mobile' | translate }}"
            pattern="^[0-9 +\-]{5,20}$"
            required />
        </div>
      </div>
      <!-- notes -->
      <div class="form-group row mb-3">
        <div class="col-xs-12 col-sm-4 col-md-3">
          <label class="col-form-label text-xs-right">{{
            'notes' | translate
          }}</label>
        </div>
        <div class="col-xs-12 col-sm-8 col-md-9">
          <textarea
            type="text"
            formControlName="notes"
            class="form-control"
            placeholder="{{ 'wishes' | translate }}"></textarea>
        </div>
      </div>
      <!-- accept marketing -->
      <div class="form-group row">
        <div class="col-xs-12 col-sm-4 col-md-3"></div>
        <div class="col-xs-12 col-sm-8 col-md-9">
          <label class="form-check-label mb-0 d-flex">
            <input
              class="form-check-input me-2 flex-shrink-0"
              formControlName="marketing_agreed"
              type="checkbox" />
            <span [innerHTML]="'byt_accept_marketing' | translate"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</form>
