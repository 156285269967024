import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ComponentsModule } from '@components/components.module';
import { environment } from '@environment/environment';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@pipes/pipes.module';
import {
  appEnvFactory,
  AppEnvService
} from '@services/app-env/app-env.service';
import { BaseService } from '@services/base/base.service';
import { AuthGuard } from '@shared/guards/auth/auth.guard';
import { PlannerGuard } from '@shared/guards/planner/planner.guard';
import { BrowserErrorLogger } from '@shared/utils/browser-logger/browser-logger';
import { CustomHttpInterceptor } from '@shared/utils/http-interceptor/http-interceptor';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxStripeModule } from 'ngx-stripe';
import { ToastrModule } from 'ngx-toastr';

// Modules, Components, Services
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DirectivesModule } from './directives/directives.module';
import { translateBrowserLoaderFactory } from './shared/loaders/translate-browser.loader';

const GUARDS = [AuthGuard, PlannerGuard];
const SERVICES = [AppEnvService, BaseService];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'ta-web-app' }),
    BsDatepickerModule.forRoot(), // need to place here otherwise locales were not recognized globally
    ComponentsModule,
    PipesModule,
    DirectivesModule,
    TransferHttpCacheModule,
    HttpClientModule,
    NgxStripeModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState]
      }
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-full-width',
      preventDuplicates: true
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    AppRoutingModule
  ],
  providers: [
    ...GUARDS,
    ...SERVICES,
    {
      provide: APP_INITIALIZER,
      useFactory: appEnvFactory,
      deps: [AppEnvService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: BrowserErrorLogger
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
