<ng-container *ngIf="offer">
  <ul class="list-orange list-orange-icon">
    <!-- accommodation -->
    <ng-container *ngIf="offer?.accommodation?.details.length > 0">
      <li>
        {{ offer.accommodation?.details.length }}
        {{ 'accommodation_stays' | translate }}<br />
        {{ 'accommodation_text' | translate }}
      </li>
    </ng-container>

    <!-- mobility -->
    <!-- mobility luggage-->
    <li
      *ngIf="
        offer?.mobility?.luggage_price &&
        offer?.mobility?.luggage_price.total > 0
      ">
      {{ 'byt_trail_mobility_luggage' | translate }}
      <!-- check for regular luggage transfer -->
      <br />
      <ng-container *ngFor="let luggage of luggageMobilityServices">
        <div>
          {{ luggage?.date }}: {{ luggage?.stage }}
          {{ 'byt_trail_mobility_luggage' | translate }}
          {{ 'byt_von' | translate }} {{ luggage?.from }}
          {{ 'byt_bis' | translate }} {{ luggage?.to }}
        </div>
      </ng-container>
    </li>

    <!-- mobility shuttles -->
    <li
      *ngIf="
        offer?.mobility?.people_price && offer?.mobility?.people_price.total > 0
      ">
      {{ 'byt_trail_mobility_shuttle' | translate }}<br />
      <ng-container
        *ngFor="
          let accommodation of offer?.accommodation?.details;
          let i = index
        ">
        <ng-container
          *ngFor="
            let mobility of offer?.mobility?.people_price?.details;
            let j = index
          ">
          <span *ngIf="mobility?.day.substring(0, 10) === accommodation.date">
            <div
              *ngIf="
                mobility?.people_going_price > 0 &&
                mobility?.people_total_price > 0
              ">
              <span
                >{{ mobility.day.substring(8, 10) }}.{{
                  mobility.day.substring(5, 7)
                }}:
                {{ accommodation?.stage?.code }}
              </span>
              <span> {{ 'individual_shuttle' | translate }}</span>
              <span
                *ngIf="
                  offer?.accommodation?.details[i - 1]?.selected_provider
                    ?.provider.name
                ">
                {{ 'byt_von' | translate }}
                {{
                  offer.accommodation?.details[i - 1]?.selected_provider
                    ?.provider.name
                }}</span
              >
              <span
                *ngIf="
                  !offer?.accommodation?.details[i - 1]?.selected_provider
                    ?.provider.name
                ">
                {{ 'byt_von' | translate }}
                {{
                  offer.accommodation?.details[i]?.selected_provider?.provider
                    .name
                }}</span
              >
              <span>
                {{ 'byt_bis' | translate }}
                {{ 'stage_start' | translate }}</span
              >
              <span>
                ({{ 'byt_distance' | translate }}
                {{ mobility?.people_going_distance?.toFixed(0) }} km)</span
              >
            </div>
            <div
              *ngIf="
                mobility?.people_returning_price > 0 &&
                mobility?.people_total_price > 0
              ">
              <span>
                {{ mobility?.day_returning?.substring(8, 10) }}.{{
                  mobility?.day_returning?.substring(5, 7)
                }}:
                {{ accommodation?.stage?.code }}
              </span>
              <span>
                {{ 'individual_shuttle' | translate }}
                {{ 'byt_von' | translate }} {{ 'stage_end' | translate }}</span
              >
              <span>
                {{ 'byt_bis' | translate }}
                {{
                  offer.accommodation?.details[i]?.selected_provider?.provider
                    .name
                }}</span
              >
              <span>
                ({{ 'byt_distance' | translate }}
                {{ mobility?.people_returning_distance?.toFixed(0) }} km)</span
              >
            </div>
          </span>
        </ng-container>
      </ng-container>
    </li>

    <!-- Individual mobility-->
    <li *ngIf="ind_services && individualMobilityServices.length > 0">
      {{ 'byt_trail_mobility_further_shuttle' | translate }}<br />
      <ng-container
        *ngFor="let service of individualMobilityServices; let i = index">
        {{ service?.date.substring(8, 10) }}.{{
          service?.date.substring(5, 7)
        }}: {{ service?.stage }} {{ service?.name | getText : currLang }}<br />
      </ng-container>
    </li>

    <!-- Mobility backtransfer-->
    <li
      *ngIf="
        offer?.mobility?.back_transfer && offer?.mobility?.back_price.total > 0
      ">
      {{ 'byt_trail_mobility_backtransfer' | translate }}
      <ng-container
        *ngFor="
          let mobility of offer?.mobility?.back_price?.details;
          let j = index
        ">
        <div *ngIf="mobility?.back_price > 0">
          {{ mobility.day.substring(8, 10) }}.{{
            mobility.day.substring(5, 7)
          }}:
          <span>
            {{ 'byt_trail_mobility_collectivetransfer' | translate }}
            {{ 'byt_von' | translate }}
            {{
              offer.accommodation?.details[
                offer.accommodation?.details.length - 1
              ]?.selected_provider?.provider.name
            }}</span
          >
          <span>
            {{ 'byt_bis' | translate }}
            {{
              offer.accommodation?.details[0]?.selected_provider?.provider.name
            }}</span
          >
          <span>
            ({{ 'byt_distance' | translate }}
            {{ mobility?.back_distance.toFixed(0) }} km)</span
          >
        </div>
      </ng-container>
    </li>

    <!-- Packages-->
    <ng-template
      ngFor
      let-service
      [ngForOf]="offer.individual_services"
      let-i="index">
      <li *ngIf="service.price?.details[0].product?.type === 'Guide'">
        {{
          service.price?.details[0].product.descriptions | getText : currLang
        }}
      </li>
    </ng-template>

    <!-- Packages-->
    <ng-template
      ngFor
      let-service
      [ngForOf]="offer.individual_services"
      let-i="index">
      <li *ngIf="service.price?.details[0].product?.type === 'Package'">
        {{
          service.price?.details[0].product.descriptions | getText : currLang
        }}
      </li>
    </ng-template>

    <!-- Tickets-->
    <ng-template
      ngFor
      let-service
      [ngForOf]="offer.individual_services"
      let-i="index">
      <li *ngIf="service.price?.details[0].product?.type === 'Ticket'">
        {{
          service.price?.details[0].product.descriptions | getText : currLang
        }}
      </li>
    </ng-template>

    <!-- Angel Service-->
    <ng-template
      ngFor
      let-service
      [ngForOf]="offer.individual_services"
      let-i="index">
      <li *ngIf="service.price?.details[0].product?.type === 'AngelService'">
        {{
          service.price?.details[0].product?.descriptions | getText : currLang
        }}
      </li>
    </ng-template>
  </ul>
  <hr />
</ng-container>
