/*
 * Base class for all components. Sets some default values for all child components.
 */

import { Component, OnDestroy } from '@angular/core';
import { siteSettings } from '@environment/site-settings';
import { Enviroment } from '@interfaces/environment.interface';
import { SiteSettings } from '@interfaces/site-settings';
import { BaseService } from '@services/base/base.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-base-component',
  template: ''
})
export class BaseComponent implements OnDestroy {
  // declaration of fields
  public currLang: string;
  public defaultImage = 'assets/images/elements/defaultImage.jpg';
  public environment: Enviroment;
  public isBrowser: boolean;
  public isLoading = false;
  public isLoggedIn: boolean;
  public isServer: boolean;
  public item: Observable<any>;
  public list: Observable<any>;
  public siteSettings: SiteSettings;
  public unsubscribe$ = new Subject<void>();

  constructor(public baseService: BaseService) {
    this.isBrowser = this.baseService.isBrowser();
    this.isServer = this.baseService.isServer();

    // set environment
    this.environment = this.baseService.getEnv();
    this.siteSettings = siteSettings;

    this.baseService.isLoggedIn
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state: boolean) => {
        this.isLoggedIn = state;
      });

    // add subscriber for currentLang
    this.baseService.currentLang
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((lang: string) => {
        this.currLang = lang;
      });
  }

  ngOnDestroy() {
    if (this.unsubscribe$) {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }
  }
}
